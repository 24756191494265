/**
 * Accordion component
 *
 * @usage
 *
 * <Accordion>
 *   <AccordionItem
 *     title="¿Qué ... pregunta lorem ipsum dolor sit?"
 *     itemKey="a"
 *   >
 *     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet
 *     ultricies id venenatis, commodo molestie commodo. Eget facilisis
 *     gravida tellus eu senectus ut adipiscing. Sit at platea mi sed
 *     eros.
 *   </AccordionItem>
 *   <AccordionItem title="Section 2" itemKey="b">
 *      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet
 *     ultricies id venenatis, commodo molestie commodo. Eget facilisis
 *     gravida tellus eu senectus ut adipiscing. Sit at platea mi sed
 *     eros.
 *   </AccordionItem>
 * </Accordion>
 */

import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const AccordionContainer = styled.div``;

const AccordionItemContainer = styled.div`
  border-bottom: 1px solid var(--etpay-color-border);

  &:last-child {
    border-bottom: none;
  }
`;

const AccordionItemTitleContainer = styled.div`
  @media (max-width: 1200px) {
    font-size: var(--etpay-font-size-11);
    line-height: 24px;
  }
  display: var(--etpay-display-flex);
  justify-content: space-between;
  align-items: center;
  flex-direction: var(--etpay-flex-direction-row);
  padding: 24px 0;

  font-style: normal;
  font-weight: 400;
  font-size: var(--etpay-font-size-15);
  line-height: 34px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);

  &:hover {
    cursor: pointer;
  }
`;

const AccordionItemTitleContent = styled.div``;

const AccordionItemTitleAction = styled.div`
  align-items: center;
  display: var(--etpay-display-flex);
`;

const AccordionItemTitleActionIcon = styled.img`
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease-in-out;

  transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : 'rotate(0deg)')};
`;

const AccordionItemContent = styled.div`
  @media (max-width: 1200px) {
    font-size: var(--etpay-font-size-11);
    line-height: 24px;
    padding: 24px 10px 24px 10px
  }
  transition: display 0.3s ease-in-out;
  padding: 28px 51px 16px 44px;
  background: var(--etpay-color-sirus);
  border-radius: 12px;
  margin-bottom: 24px;

  display: ${(props) => (props.isActive ? 'block' : 'none')};

  font-style: normal;
  font-weight: 400;
  font-size: var(--etpay-font-size-12);
  line-height: 34px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);
`;

export const Accordion = ({ children }) => {
  const [active, setActive] = useState('');
  let items;

  const toggle = useCallback(
    (key) => {
      if (key === active) {
        // close opened accordion
        setActive('');
      } else {
        setActive(key);
      }
    },
    [active]
  );

  if (!children.hasOwnProperty('length')) {
    items = [children];
  } else {
    items = children;
  }

  useEffect(() => {
    toggle(items[0].props.itemKey);
  }, []);
  return (
    <AccordionContainer>
      {items.map((el, index) => (
        <AccordionItemContainer>
          <AccordionItemTitleContainer onClick={() => toggle(el.props.itemKey)}>
            <AccordionItemTitleContent>
              {el.props.title}
            </AccordionItemTitleContent>
            <AccordionItemTitleAction>
              <AccordionItemTitleActionIcon
                src={require('../../assets/icons/icon_plus.png')}
                isActive={el.props.itemKey === active}
              />
            </AccordionItemTitleAction>
          </AccordionItemTitleContainer>
          <AccordionItemContent isActive={el.props.itemKey === active}>
            {el.props.children}
          </AccordionItemContent>
        </AccordionItemContainer>
      ))}
    </AccordionContainer>
  );
};

export const AccordionItem = ({ title, children }) => {
  return <>{children}</>;
};
