import { useIntl } from "react-intl";
import styled from "styled-components";
import { Button, Carousel, Layout } from "../../components";
import { Icon } from "../../components/Icon/Icon";
import { PeopleOfficeInformation } from "../../components/PeopleOfficeInformation/PeopleOfficeInformation";
import { PeopleOfficePlace } from "../../components/PeopleOfficePlace";
import { PeopleOfficeTeamPhoto } from "../../components/PeopleOfficeTeamPhoto";
import { PeopleOfficeTitle } from "../../components/PeopleOfficeTitle/PeopleOfficeTitle";
import { Positioned } from "../../components/Positioned";
import useAppContext from "../../context/Context";
import { Frame } from "../../components";
import './People.scss';
import videos from '../../videos/videos.json'
import ReactGA from "react-ga4";

const PeopleRoot = styled.div`
  padding-top: 87px;
  position: var(--etpay-position-relative);
  overflow-x: hidden;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
`;

const PeopleRootContainer = styled.div``;

const SectionContent = styled.div``;

const SectionRow = styled.div`
  .flex-grid__mobile__cols-6{
    @media (max-width: 992px) {
      grid-column: 2 / 6 !important;
    }
  }
`;

// =============================================================================
// == Section 1
// =============================================================================
const Section1 = styled.section`
  @media (max-width: 992px) {
    padding-bottom: 80px;
  }
  padding-bottom: 220px;
`;

// =============================================================================
// == Section 2
// =============================================================================
const Section2 = styled.section`
  @media (max-width: 992px) {
    padding-bottom: 71px;
  }
  padding-bottom: 146px;
`;

// =============================================================================
// == Section 3
// =============================================================================

const Section3 = styled.section`
  @media (max-width: 992px) {
    padding-bottom: 71px;
  }
  padding-bottom: 146px;
`;

// =============================================================================
// == Section 4
// =============================================================================
const Section4 = styled.section`
  @media (max-width: 992px) {
    padding-bottom: 110px;
  }
  padding-bottom: 146px;
  position: var(--etpay-position-relative);
  //border: 4px solid red;
  overflow: hidden;
`;

const Section4SectionTitle = styled.div`
  @media (max-width: 992px) {
    padding-top: 71px;
    padding-bottom: 78px;
  }
  padding-top: 141px;
  padding-bottom: 100px;
  display: var(--etpay-display-grid);

  > .left {
    display: var(--etpay-display-flex);
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: var(--etpay-flex-direction-column);

    > .content-top {
      font-style: normal;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      /* identical to box height, or 133% */

      letter-spacing: -0.0008em;

      /* Extra / Earth */

      color: var(--etpay-color-blue);

      margin-bottom: 24px;
    }

    > .content-bottom {
      font-style: normal;
      font-weight: 500;
      font-size: var(--etpay-font-size-26);
      line-height: 114%;
      /* identical to box height, or 48px */

      letter-spacing: -0.0008em;

      /* ETpay/Orion */

      color: var(--etpay-color-orion);
    }
  }

  .right {
    .content-top {
      font-style: normal;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      /* or 133% */

      letter-spacing: -0.0008em;

      /* ETpay/Orion */

      color: var(--etpay-color-orion);

      margin-bottom: 24px;
    }
  }

  .people-advantage {
    @media screen and (max-width: 768px) {
      grid-column: 1 / 7;
    }
    grid-column: 6 / 9;
  }
`;

const InfoCard = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
`;

const InfoCardIcon = styled.div`
  margin-bottom: 24px;
`;

const InfoCardContent = styled.div`
  /* Web Desktop/P */

  font-family: "Oatmeal Pro";
  font-style: normal;
  font-weight: 400;
  font-size: var(--etpay-font-size-15);
  line-height: 32px;
  /* or 133% */

  letter-spacing: -0.0008em;

  /* ETpay/Orion */

  color: var(--etpay-color-orion);

  border-top: 1px solid var(--etpay-color-border);

  padding-top: 18px;
  & b{
    font-weight: 500
  }
`;

const PeopleRootContentObj = styled.div`
  background: radial-gradient(
      48.69% 48.69% at 60.1% 37.87%,
      #72dbaa 0%,
      rgba(234, 255, 238, 0.2) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 50%;
  width: 1501px;
  height: 1501px;
  opacity: 0.6;
`;

// =============================================================================
// == Section 5
// =============================================================================
const PeopleRootContentSection5 = styled.div`
  padding-top: 117px;
  padding-bottom: 87px;
`;

export const People = () => {
  ReactGA.send({ hitType: "pageview", page: "/people-office" });

  const intl = useIntl();
  const { getImages } = useAppContext()
  const section4 = [
    {
      icon: "health",
      content: intl.formatMessage({
        id: "peopleOfficePlace.section4.cards.content1",
      }),
    },
    {
      icon: "holiday",
      content: intl.formatMessage({
        id: "peopleOfficePlace.section4.cards.content2",
      }),
    },
    {
      icon: "workshop",
      content: intl.formatMessage({
        id: "peopleOfficePlace.section4.cards.content3",
      }),
    },
    {
      icon: "remote",
      content: intl.formatMessage({
        id: "peopleOfficePlace.section4.cards.content4",
      }),
    },
  ];

  const handleVideos = () => {
    let count = 18;
    let array = [];

    for (let index = 1; index <= count; index++) {
      array.push(
        <Frame uri={videos[`video_${index}`] && videos[`video_${index}`]} />
      )
    }

    return array;
  }

  return (
    <Layout>
      <PeopleRoot>
        <Positioned top="80px" right="-100">
          <PeopleRootContentObj />
        </Positioned>

        <Section1 className="layout-container">
          <SectionContent className="flex-grid">
            <SectionRow className="flex-grid__cols-9 flex-grid__cols__offset-1 flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none mobile-flex">
              <PeopleOfficeTitle
                title={intl.formatMessage({ id: "people.banner.title" })}
                text={intl.formatMessage({ id: "people.banner.text" })}
              />              
            </SectionRow>
          </SectionContent>
        </Section1>

        <Section2 className="layout-container">
          <SectionContent className="flex-grid" style={{ position: "relative" }}>
            <SectionRow className="flex-grid__cols-12">
              <div className="desktop-mode">
                <Carousel>
                  <div className="people-team-photo">
                    <PeopleOfficeTeamPhoto
                      photos={[
                        getImages['people_office_1'],
                      ]}
                    />
                  </div>
                  <div className="people__office-video_frame">
                    {
                      handleVideos()
                    }
                  </div>
                </Carousel>
              </div>
              <div className="mobile-mode">
                <PeopleOfficeTeamPhoto
                  photos={[
                    getImages['people_office_1'],
                  ]}
                />
                <Carousel>
                  <div className="people__office-video_frame">
                    {
                      handleVideos()
                    }
                  </div>
                  <div className="people__office-video_frame">
                    {
                      handleVideos()
                    }
                  </div>
                </Carousel>
              </div>
            </SectionRow>
          </SectionContent>
        </Section2>

        <Section3 className="layout-container">
          <SectionContent className="flex-grid">
            <SectionRow className="flex-grid__cols-9 flex-grid__cols__offset-1 flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none">
              <PeopleOfficeInformation
                text={intl.formatMessage({ id: "people.information.text" })}
                actionText={intl.formatMessage({
                  id: "people.information.actionText",
                })}
                sponsorHeaderText={intl.formatMessage({
                  id: "people.information.sponsorHeader",
                })}
                sponsorLogos={[
                  getImages['sponsor_1'],
                  getImages['sponsor_2'],
                  getImages['sponsor_3'],
                ]}
              />
            </SectionRow>
          </SectionContent>
        </Section3>

        <Section4>
          <Positioned top="0" right="0px">
            <img
              src={require("../../assets/images/people/img_006.webp")}
              alt="Img"
              height={"760px"}
            />
          </Positioned>

          <SectionContent className="layout-container flex-grid">
            <SectionRow className="flex-grid__cols-12 flex-grid__mobile__cols-6">
              <Section4SectionTitle className="flex-grid">
                <div className="flex-grid__cols-5 left">
                  <div className="content-top">
                    {intl.formatMessage({
                      id: "peopleOfficePlace.section4.text1",
                    })}
                  </div>
                  <div className="content-bottom">
                    {intl.formatMessage({
                      id: "peopleOfficePlace.section4.text2",
                    })}
                  </div>
                </div>
                <div className="flex-grid__cols-7 right people-advantage">
                  <div className="content-top">
                    {intl.formatMessage({
                      id: "peopleOfficePlace.section4.text3",
                    })}
                  </div>
                  <a href={intl.formatMessage({ id: "join.us"})}>
                  <Button bgColor="#EFEFEF">
                    {intl.formatMessage({
                      id: "peopleOfficePlace.section4.action",
                    })}
                    <Icon name="Icon_After" hoverColor="#ffffff" />
                  </Button>
                  </a>
                </div>
              </Section4SectionTitle>
            </SectionRow>

            <SectionRow className="flex-grid__cols-12 flex-grid__mobile__cols-6 flex-grid">
              {section4.map((item, index) => (
                <InfoCard
                  key={item.icon}
                  className="flex-grid__cols-3 flex-grid__mobile__cols-6"
                >
                  <InfoCardIcon>
                    <Icon name={item.icon} size="48px" />
                  </InfoCardIcon>
                  <InfoCardContent
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></InfoCardContent>
                </InfoCard>
              ))}
            </SectionRow>
          </SectionContent>
        </Section4>

        <PeopleRootContentSection5>
          <SectionContent className="layout-container flex-grid">
            <SectionRow className="flex-grid__cols-12">
              <PeopleOfficePlace />
            </SectionRow>
          </SectionContent>
        </PeopleRootContentSection5>
      </PeopleRoot>
    </Layout>
  );
};
