import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  Icon,
  Banner,
  Content,
  Button,
  ReadyToStart,
  Slider,
  SliderItem,
  Layout,
} from "../../components";
import { paymentLink, quickTransfer } from "../../services/animationIcons";
import "./UseCases.scss";
import useAppContext from "../../context/Context";
const color = "#72DBAA";

export const UseCases = () => {
  ReactGA.send({ hitType: "pageview", page: "/uses-cases" });

  const { activeCountry } = useAppContext();

  const { getImages } = useAppContext();
  const intl = useIntl();

  return (
    <Layout>
      <div className="use-cases">
        <Banner customClass="banner banner-useCases">
          <Content
            title={intl.formatMessage({ id: "useCases.banner.title" })}
            subtitle={intl.formatMessage({ id: "useCases.banner.subtitle" })}
            footer={
              <>
                <a
                  href={intl.formatMessage({
                    id: "button.testFree.url",
                  })}
                >
                  <Button bgColor={color} bgHoverColor="#140F33" color="#fff">
                    <Icon name={"Icon_Pre"} className="fillable" />
                    <span>
                      {intl.formatMessage({ id: "button.testFree.text" })}
                    </span>
                  </Button>
                </a>


                <a href={
                'tel:'+intl.formatMessage({ id: "banner.home.button2.url" })
              }>
                <Button bgColor={"#F2F2F2"} bgHoverColor="#140F33">
                  <span>
                    {intl.formatMessage({ id: "banner.home.button2.text" })+intl.formatMessage({ id: "banner.home.button2.url" })}
                  </span>
                  <Icon name="icon-phone"></Icon>
                </Button>
              </a>
              </>
            }
            customClass="grid-6"
          />
          <img
            className="back-icon"
            src={getImages["banner_background_use_case"]}
            alt=""
          />
          <div
            className="image-banner"
            style={{
              gridColumn: "7 / 13",
              marginTop: 0,
            }}
          >
            <img style={{ width: '80%' }} src={getImages["banner_image_use_case"]} alt="" />
          </div>
        </Banner>

        {activeCountry === 'mx' ? '' : <Slider
          icon={paymentLink}
          title={intl.formatMessage({ id: "useCases.slider1.title" })}
          description={intl.formatMessage({
            id: "useCases.slider1.description",
          })}
          serviceName="Payment Links"
        >
          <SliderItem
            picture={getImages["slider_1"]}
            title={intl.formatMessage({
              id: "useCases.slider1.item1.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider1.item1.description",
            })}
            service="paymentlink"
          ></SliderItem>
          <SliderItem
            picture={getImages["slider_2"]}
            title={intl.formatMessage({
              id: "useCases.slider1.item2.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider1.item2.description",
            })}
            service="paymentlink"
          ></SliderItem>
          <SliderItem
            picture={getImages["slider_3"]}
            title={intl.formatMessage({
              id: "useCases.slider1.item3.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider1.item3.description",
            })}
            service="paymentlink"
          ></SliderItem>
        </Slider>}
        <Slider
          icon={quickTransfer}
          title={intl.formatMessage({ id: "useCases.slider2.title" })}
          description={intl.formatMessage({
            id: "useCases.slider2.description",
          })}
          serviceName="Quick Transfer"
        >
          <SliderItem
            picture={getImages["slider_4"]}
            title={intl.formatMessage({
              id: "useCases.slider2.item1.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider2.item1.description",
            })}
            service="quicktransfer"
          ></SliderItem>
          <SliderItem
            picture={getImages["slider_5"]}
            title={intl.formatMessage({
              id: "useCases.slider2.item2.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider2.item2.description",
            })}
            service="quicktransfer"
          ></SliderItem>
          <SliderItem
            picture={getImages["slider_6"]}
            title={intl.formatMessage({
              id: "useCases.slider2.item3.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider2.item3.description",
            })}
            service="quicktransfer"
          ></SliderItem>
        </Slider>
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  );
};
