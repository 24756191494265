import { Column } from '..';
import './Tips.scss';

export const Tips = ({ tip1, tip2, tip3, ...otherProps }) => {
  return (
    <Column customClass="tips">
      <div className="text" dangerouslySetInnerHTML={{ __html: tip1 }}></div>
      <div className="text" dangerouslySetInnerHTML={{ __html: tip2 }}></div>
      <div className="text" dangerouslySetInnerHTML={{ __html: tip3 }}></div>
    </Column>
  );
};
