import React, { useState } from "react";
import { Link, useMatch } from "react-router-dom";

import { Icon, ItemList, Button, AnimateIcon, Select } from "..";
import "./Header.scss";
import { useIntl } from "react-intl";
import { paymentLink, quickTransfer } from "../../services/animationIcons";
import useAppContext from "../../context/Context";

export const Header = ({ fixed }) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const match = useMatch("developers");
  const people = useMatch("people-office");
  const cookies = useMatch("cookies");
  const terms = useMatch("terms-conditions");
  const policy = useMatch("privacy-policy")
  const { countries, activeCountry } = useAppContext()

  return (
    <header
      className={`header-page ${match && "developer-page"} ${fixed ? "menu-fixed" : ""
        }`}
    >
      <div className="header-page-container">
        <div className={`mobile-title ${match && "developer-page"}`}>
          <div className="logo">
            <Link to="/" className="logo"
              onClick={() => setOpen(false)}>
              <Icon name="UFO" size="25px" className="etpayLogo" />
              <Icon name="Lettering" />
            </Link>
            <div
              className={`menuToggle ${open ? "open" : ""}`}
              onClick={() => setOpen(!open)}
            ></div>
          </div>
        </div>

        <div
          className={`mobileMenu ${open ? "open" : ""} ${match && "developer-page"
            }`}
        >
          <div className="mobile-title">
            <div className="logo">
              <Link to="/" className="logo"
                onClick={() => setOpen(false)}>
                <Icon name="UFO" size="25px" className="etpayLogo" />
                <Icon name="Lettering" />
              </Link>
              <div
                className={`menuToggle ${open ? "open" : ""}`}
                onClick={() => setOpen(!open)}
              ></div>
            </div>
          </div>
          {!people && !cookies && !policy && !terms ? (
            <>
              <div className="products-services">
                <section className="products">
                  <span>
                    {intl.formatMessage({ id: "header.products.title" })}
                  </span>
                  <div className="productMenuContainer">
                    <Link
                      to="quick-transfer"
                      onClick={() => setOpen(false)}
                    >
                      <ItemList>
                        {open ? <AnimateIcon json={quickTransfer} /> : ''}
                        <span>
                          {intl.formatMessage({
                            id: "header.products.menu.quickTransfer.title",
                          })}
                        </span>
                      </ItemList>
                    </Link>

                    {activeCountry === 'mx' ? '' : <Link
                      to="payment-links"
                      onClick={() => setOpen(false)}
                    >
                      <ItemList>
                        {open ? <AnimateIcon json={paymentLink} /> : ''}
                        <span>
                          {intl.formatMessage({
                            id: "header.products.menu.paymentLinks.title",
                          })}
                        </span>
                      </ItemList>
                    </Link>}
                  </div>
                </section>
                {/* Services */}
                <section className="services">
                  <span>
                    {intl.formatMessage({
                      id: "header.services.title",
                    })}
                  </span>
                  <div className="servicesContainer">
                    <Link
                      to="conciliation"
                      onClick={() => setOpen(false)}
                    >
                      <ItemList>
                        <Icon name="Conciliacion" />
                        <span>
                          {intl.formatMessage({
                            id: "header.products.menu.conciliation.title",
                          })}
                        </span>
                      </ItemList>
                    </Link>
                    {activeCountry === 'mx' ? '' : <Link
                      to="returns"
                      onClick={() => setOpen(false)}
                    >
                      <ItemList>
                        <Icon name="Returns" />
                        <span>
                          {intl.formatMessage({
                            id: "header.products.menu.returns.title",
                          })}
                        </span>
                      </ItemList>
                    </Link>}
                  </div>
                </section>
                <section className="resources">
                  <span>
                    {intl.formatMessage({
                      id: "header.resource.title",
                    })}
                  </span>
                  <div className="resourcesContainer">
                    <a href={intl.formatMessage({
                      id: "header.help.url",
                    })} onClick={() => setOpen(false)}>
                      {intl.formatMessage({
                        id: "header.help.title",
                      })}
                    </a>

                    <Link
                      to="developers"
                      onClick={() => setOpen(false)}
                    >
                      {intl.formatMessage({
                        id: "header.developers.title",
                      })}
                    </Link>

                    <Link
                      to="prices"
                      onClick={() => setOpen(false)}
                    >
                      {intl.formatMessage({
                        id: "header.price.title",
                      })}
                    </Link>
                  </div>
                </section>
              </div>

              <div className="signIn">
                <div className="btn-container">
                  <a href="https://merchant-dashboard.etpayment.com/#/"><Button bgHoverColor="#fff">
                    <span>
                      {intl.formatMessage({
                        id: "header.signIn.title",
                      })}
                    </span>
                  </Button></a>
                  <a
                    href={intl.formatMessage({
                      id: "button.testFree.url",
                    })}
                  >
                    <Button bgHoverColor="#140f33">
                      <span>
                        {intl.formatMessage({
                          id: "button.testFree.text",
                        })}
                      </span>
                    </Button>
                  </a>
                </div>
              </div>
            </>
          ) : cookies || policy || terms ? (
            <Select />
          ) : (
            <div className="signIn">
              <a href={intl.formatMessage({ id: 'join.us' })}>
                <div className="btn-container">
                  <Button bgHoverColor="#140f33" bgColor="#EFEFEF">
                    <span>
                      {intl.formatMessage({
                        id: "people.header.button",
                      })}
                    </span>
                  </Button>
                </div>
              </a>
            </div>
          )}
        </div>

        <div className="desktopMenu">
          <Link to="/" className="logo"
            onClick={() => setOpen(false)}>
            <Icon name="UFO" size="25px" className="etpayLogo" />
            <Icon name="Lettering" />
          </Link>
          {!people && !cookies && !policy && !terms ? (
            <>
              <nav>
                <div className="">
                  <ItemList
                    className="itemList"
                    onHandleClick={() => setOpen(!open)}
                  >
                    <span>
                      {" "}
                      {intl.formatMessage({ id: "header.products.title" })}
                    </span>
                    <Icon name="Vector" className={`${open && "open"}`} />
                  </ItemList>
                  <div className={`submenu ${open && "open"}`}>
                    <div className="submenu-container">
                      <div className="productsContainer">
                        <span className="title">
                          {intl.formatMessage({ id: "header.products.title" })}
                        </span>
                        <div className="products">
                          <Link
                            to="quick-transfer"
                            onClick={() => setOpen(false)}
                          >
                            <ItemList>
                              <AnimateIcon json={quickTransfer} size="52px" />
                              <div>
                                <span>
                                  {intl.formatMessage({
                                    id: "header.products.menu.quickTransfer.title",
                                  })}
                                </span>
                                <p className="subtitle">
                                  {intl.formatMessage({
                                    id: "header.products.menu.quickTransfer.subtitle",
                                  })}
                                </p>
                              </div>
                            </ItemList>
                          </Link>

                          {activeCountry === 'mx' ? '' : <Link
                            to="payment-links"
                            onClick={() => setOpen(false)}
                          >
                            <ItemList>
                              <AnimateIcon json={paymentLink} size="52px" />
                              <div>
                                <span>
                                  {intl.formatMessage({
                                    id: "header.products.menu.paymentLinks.title",
                                  })}
                                </span>
                                <p className="subtitle">
                                  {intl.formatMessage({
                                    id: "header.products.menu.paymentLinks.subtitle",
                                  })}
                                </p>
                              </div>
                            </ItemList>
                          </Link>}


                        </div>
                      </div>
                      <div className="servicesContainer">
                        <span className="title">Servicios</span>
                        <div className="services">
                          <Link
                            to="conciliation"
                            onClick={() => setOpen(false)}
                          >
                            <ItemList>
                              <Icon name="Conciliacion" />
                              <div>
                                <div className="d-flex">
                                  <span>
                                    {intl.formatMessage({
                                      id: "header.products.menu.conciliation.title",
                                    })}
                                  </span>
                                </div>
                                <p className="subtitle">
                                  {intl.formatMessage({
                                    id: "header.products.menu.conciliation.subtitle",
                                  })}
                                </p>
                              </div>
                            </ItemList>
                          </Link>
                          {activeCountry === 'mx' ? '' :
                            <Link
                              to="returns"
                              onClick={() => setOpen(false)}
                            >
                              <ItemList>
                                <Icon name="Returns" />
                                <div>
                                  <div className="d-flex">
                                    <span>
                                      {intl.formatMessage({
                                        id: "header.products.menu.returns.title",
                                      })}
                                    </span>
                                  </div>
                                  <p className="subtitle">
                                    {intl.formatMessage({
                                      id: "header.products.menu.returns.subtitle",
                                    })}
                                  </p>
                                </div>
                              </ItemList>
                            </Link>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a href={
                  intl.formatMessage({
                    id: "header.help.url",
                  })
                }>
                  <Button bgColor="#fff">
                    <span>
                      {intl.formatMessage({
                        id: "header.help.title",
                      })}
                    </span>
                  </Button>
                </a>
                <Link
                  to="developers"
                  onClick={() => setOpen(false)}
                >
                  <Button bgColor="#fff">
                    <span>
                      {intl.formatMessage({
                        id: "header.developers.title",
                      })}
                    </span>
                  </Button>
                </Link>
                <Link
                  to="prices"
                  onClick={() => setOpen(false)}
                >
                  <Button bgColor="#fff">
                    <span>
                      {intl.formatMessage({
                        id: "header.price.title",
                      })}
                    </span>
                  </Button>
                </Link>
              </nav>
              <div className="signIn">
                <a href="https://merchant-dashboard.etpayment.com/">
                  <Button bgHoverColor="#140f33" bgColor="#FCFCFC">
                    <span>
                      {intl.formatMessage({
                        id: "header.signIn.title",
                      })}
                    </span>
                  </Button>
                </a>
                <a
                  href={intl.formatMessage({
                    id: "button.testFree.url",
                  })}
                >
                  <Button bgHoverColor="#140f33">
                    <span>
                      {intl.formatMessage({
                        id: "button.testFree.text",
                      })}
                    </span>
                  </Button>
                </a>
              </div>
            </>
          ) : cookies || policy || terms ? (
            <Select />
          ) :
            (
              <a href={intl.formatMessage({ id: 'join.us' })}>
                <div className="signIn">
                  <Button
                    bgHoverColor="#140f33"
                    colorHover="#fff"
                    bgColor="#EFEFEF"
                  >
                    {intl.formatMessage({
                      id: "people.header.button",
                    })}
                  </Button>
                </div>
              </a>
            )}
        </div>
      </div>
    </header>
  );
};
