import React from 'react'
import {Helmet } from "react-helmet"
import favicon from '../../assets/favicon.svg'
import { useIntl } from 'react-intl'

export const Head = () => {
    const intl = useIntl()
    return(
        <Helmet>
            <link rel="shortcut icon" href={favicon} />
            <link rel="apple-touch-icon" href={favicon} />
            <meta
                name="description"
                content={ intl.formatMessage({ id: 'content' }) }
            />
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <link rel="manifest" href="/manifest.json" />
            <title>{ intl.formatMessage({ id: 'title'}) }</title>
        </Helmet>
    )
}