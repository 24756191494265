import './InformationGroup.scss';
import { Column } from '..';

export const InformationGroup = ({
  children,
  color,
  topTitle,
  title,
  description,
  ...otherProps
}) => {
  return (
    <div className="information-group" {...otherProps}>
      <div className="back-container">
        <img className="back-container__img" src="banners/img_006.png" alt="" />
      </div>

      <Column>
        <div className="top">
          <h4
            className="top-title"
            style={{
              color: color,
            }}
          >
            {topTitle}
          </h4>
          <h2 className="title">{title}</h2>
          <h3 className="description">{description}</h3>
        </div>
      </Column>
      <Column>{children}</Column>
    </div>
  );
};
