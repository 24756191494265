import { memo, useRef, useState, useEffect } from "react";
import { Button, Icon } from "..";
import "./Select.scss";
import useAppContext from "../../context/Context";
import { useIntl } from "react-intl";

export const Select = memo(
  ({
    children,
    onHandleChange,
    onValueChanged,
    color,
    colorHover,
    bgColor,
    bgHoverColor,
    // change this option to use native select or custom select, by default use
    // modern.
    // Allowed values: native | modern
    variant = "modern",
    ...otherProps
  }) => {
    const ref = useRef();
    const intl = useIntl();
    const { countries, activeCountry, setActiveCountry } = useAppContext();
    const [open, toggleOpen] = useState(false);
    const [selected, setSelected] = useState(
        countries.find(country => country.value === activeCountry)
    );

    useEffect(()=>{
      setSelected(countries.find(country => country.value === activeCountry))
    })

    /**
     * Handle selection changed
     */
    const handleSelectionChanged = (e) => {
        const { value } = e.target
        setActiveCountry && setActiveCountry(value)
    }

    const handleSelectionModern = (value) => {
        setSelected(countries.find(country => country.value === value))
        setActiveCountry && setActiveCountry(value)
    }

    return (
      <div className={`select-root ${variant}`} ref={ref}  onClick={() => toggleOpen(!open)}>
        {variant === "native" || variant === "default" || !variant ? (
          <>
            <select
              onChange={handleSelectionChanged}
              defaultValue={activeCountry}
              style={{ backgroundColor: bgColor, color: color }}
              {...otherProps}
            >
              {
                  countries.map(country => (
                      <option value={country.value}>
                          { intl.formatMessage({ id: country.text })}
                      </option>
                  ))
              }
            </select>
            <Icon name="Vector" className="icon"></Icon>
          </>
        ) : (
          <Button>
            <span>{ intl.formatMessage({ id: selected.text })}</span>
            <Icon name="Vector" className="icon"></Icon>
          </Button>
        )}
        {variant === "modern" && (
          <div className={`select-options ${open ? "opened" : ""}`}>
            <ul>
              {countries.map(option => (
                <li
                  key={`option-${option.value}`}
                  className={`${
                    selected.value === option.value ? "selected" : ""
                  }`}
                  onClick={() => handleSelectionModern(option.value)}
                >
                  { intl.formatMessage({ id: option.text })}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
);
