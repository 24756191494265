import React from 'react'
import { Column, CardIcon } from '../../components'
import './WhyETPay.scss'
import { useIntl } from 'react-intl'

const CARDS = [
    [
        {
            icon: 'LowPrice',
            title: 'why.etpay.card_1.title',
            text: 'why.etpay.card_1.text',
            column: '1 / 4',
            item: 1
        }, {
            icon: 'Banks',
            title: 'why.etpay.card_5.title',
            text: 'why.etpay.card_5.text',
            column: '1 / 4',
            item: 2
        }
    ],
    [
        {
            icon: 'Brand',
            title: 'why.etpay.card_2.title',
            text: 'why.etpay.card_2.text',
            column: '4 / 7',
            item: 3
        }, {
            icon: 'Top',
            title: 'why.etpay.card_6.title',
            text: 'why.etpay.card_6.text',
            column: '4 / 7',
            item: 4
        }
    ],
    [
        {
            icon: 'Security',
            title: 'why.etpay.card_3.title',
            text: 'why.etpay.card_3.text',
            column: '7 / 10',
            item: 5
        }, {
            icon: 'Instant',
            title: 'why.etpay.card_7.title',
            text: 'why.etpay.card_7.text',
            column: '7 / 10',
            item: 6
        }

    ],
    [
        {
            icon: 'Everyone',
            title: 'why.etpay.card_4.title',
            text: 'why.etpay.card_4.text',
            column: '10 / 13',
            item: 7
        }, {
            icon: 'Conciliation',
            title: 'why.etpay.card_8.title',
            text: 'why.etpay.card_8.text',
            column: '10 / 13',
            item: 8
        }
    ]
]

export const WhyETPay = () => {
    const intl = useIntl()
    return (
        <Column customClass="why-etpay">
            <div className="title">
                <h1>{intl.formatMessage({ id: 'why.etpay.title' })}</h1>
                <h3 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'why.etpay.subtitle' }) }} />
            </div>
            {
                CARDS.map((colums, index) => (
                    <div key={index} className={`why-etpay__column why-etpay__${index + 1}-column`}>
                        {
                            colums.map((card, i) => (
                                <CardIcon
                                    key={i}
                                    index={card.item}
                                    icon={card.icon}
                                    title={card.title}
                                    text={card.text}
                                />
                            ))
                        }
                    </div>
                ))
            }
        </Column>
    )
}