import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { paymentLink, quickTransfer } from '../../services/animationIcons';
import { Button, AnimateIcon, Icon } from '../../components';
import './SliderItem.scss';

export const SliderItem = ({
  picture,
  title,
  description,
  service = 'paymentlink', // paymentlink, quicktransfer
  ...otherProps
}) => {
  let [isOpen, setIsOpen] = useState(false);
  const btnText = useIntl().formatMessage({ id: 'sliderItem.link' });
  const btnTextCerrar = useIntl().formatMessage({ id: 'sliderItem.close' });

  // item style width
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (window.innerWidth > 1200) {
      setWidth(
        (Math.min(1376 - 32, document.body.clientWidth - 32) / 12) * 5 - 32
      );
    } else {
      setWidth((document.body.clientWidth / 6) * 3);
    }
    // window.addEventListener('resize', () => {
    //   if (window.innerWidth > 1200) {
    //     setWidth((window.innerWidth / 12) * 5);
    //   } else {
    //     setWidth((window.innerWidth / 6) * 5);
    //   }
    // });
  }, []);
  return (
    <div
      className={`slider-item ${isOpen && 'active'} ${service}`}
      style={{ backgroundImage: `url(${picture})`, width: `${width}px` }}
      {...otherProps}
    >
      <div className="title">{title}</div>
      {isOpen && (
        <div className="center">
          <div className="service">
            <div className="icon">
              {service === 'paymentlink' ? (
                <AnimateIcon json={paymentLink} />
              ) : (
                <AnimateIcon json={quickTransfer} />
              )}
            </div>

            <div className="text">
              {service === 'paymentlink' ? 'Payment Links' : 'Quick Transfer'}
            </div>
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      )}
      <div className="btn">
        <Button
          onHandleClick={() => setIsOpen(!isOpen)}
          color={isOpen && 'white'}
          bgColor={
            isOpen ? (service === 'paymentlink' ? '#262255' : '#5c2a48') : '#EFEFEF'
          }
          bgHoverColor={service === 'paymentlink' ? '#262255' : '#5c2a48'}
        >
          {!isOpen && (
            <>
              <Icon name="plus"></Icon>
              <span className="hide-xs">{btnText}</span>
            </>
          )}
          {isOpen && <>{btnTextCerrar}</>}
        </Button>
      </div>

      <div className="back"></div>
    </div>
  );
};
