import { React, Children } from 'react';
import styled from 'styled-components';

const BankTypeList = styled.div`
  position: var(--etpay-position-relative);
  overflow: hidden;
  z-index: 3;
`;

const BankTypeListContainer = styled.div`
  overflow: hidden;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 3;
`;

const BankTypeListContainerTrack = styled.div`
  animation: scroll 40s linear infinite;
  display: var(--etpay-display-flex);
  width: 0;

    @media (max-width: 1200px) {
        animation: scroll_mobile 20s linear infinite;
    }
    @keyframes scroll {
        100% {
        transform: translateX(calc(-1250px * ${(props) => props.size}));
        }
    }
    @keyframes scroll_mobile {
        100% {
        transform: translateX(calc(-318px * ${(props) => props.size}));
        }
    }
`;

const BankTypeListItem = styled.div`
  height: 100%;
  position: relative;
  z-index: 30;
`;

const BankTypeListItemContent = styled.div`
  height: 100%;
  margin: 0px 10px;
  border-radius: 11.7143px;
  z-index: 3;
`;

export const Carousel = ({ children }) => {
    const arrayChildren = Children.toArray(children);
  return (
      <BankTypeList>
        <BankTypeListContainer>
          <BankTypeListContainerTrack
            size={
                arrayChildren
                ? arrayChildren.length + 1
                : 0
            }
          >
            {arrayChildren &&
              arrayChildren.map((child, i) => (
                <BankTypeListItem key={child+i} className="slide">
                  <BankTypeListItemContent>{ child }</BankTypeListItemContent>
                </BankTypeListItem>
              ))}
            {arrayChildren &&
              arrayChildren.map((child, i) => (
                <BankTypeListItem key={child+i} className="slide">
                  <BankTypeListItemContent>{ child }</BankTypeListItemContent>
                </BankTypeListItem>
              ))}
          </BankTypeListContainerTrack>
        </BankTypeListContainer>
      </BankTypeList>
  );
};