import { useIntl } from "react-intl";
import React from "react"

import { Column, Select, TabBox, Accordion, AccordionItem, ReadyToStart, Layout } from '../../components';
import './Faqs.scss';
import cl_quick from "../../faqs/faqs_quick_CL.json"
import mx_quick from "../../faqs/faqs_quick_MX.json"
import cl_payment from "../../faqs/faqs_payment_CL.json"
import mx_payment from "../../faqs/faqs_payment_MX.json"
import useAppContext from "../../context/Context";
import ReactGA from "react-ga4";

export const Faqs = () => {
  ReactGA.send({ hitType: "pageview", page: "/faqs" });

  const { activeCountry } = useAppContext()
  const [faqsQuick, setFaqsQuick] = React.useState(null); 
  const [faqsPayment, setFaqsPayment] = React.useState(null); 

  const handleChangeBank = () => {
    if( activeCountry === 'mx'){
      setFaqsPayment(mx_payment)
      setFaqsQuick(mx_quick)
    }else{
      setFaqsPayment(cl_payment)
      setFaqsQuick(cl_quick)
    }
    
  }

  React.useEffect(handleChangeBank,[activeCountry,faqsQuick,faqsPayment])

  
  const tab1 =
  <>{
    faqsQuick && 
    <Accordion className="tab-content">
      {
              Object.keys(faqsQuick).map((faq,i) =>(
                faq.includes("quick") &&
                <AccordionItem
                  title={faqsQuick[faq]["question"]}
                  itemKey={`item${Object.keys(faqsQuick) + i+1}`}
                >
                  { faqsQuick[faq]["answer"]}
                </AccordionItem>
              ))
      }  

    </Accordion>
  }
  </>
  const tab2 =
  <>
    {
      faqsPayment && 
      <Accordion className="tab-content">
        {
                Object.keys(faqsPayment).map((faq,i) =>(
                  faq.includes("payment") &&
                  <AccordionItem
                    title={faqsPayment[faq]["question"]}
                    itemKey={`item${Object.keys(faqsPayment) + i+1}`}
                  >
                    { faqsPayment[faq]["answer"]}
                  </AccordionItem>
                ))
        }  
  
      </Accordion>
    }
  </>
  return (
    <Layout>
      <div className="faqs">
        <Column customClass='banner'>
          <img className="back-icon" src={require('../../assets/images/banners/ETpay-grad_06.jpg')} alt="" />
          <h1 className='title'>
            {useIntl().formatMessage({ id: 'faqs.title' })}
          </h1>
          <h3 className='description'>
            {useIntl().formatMessage({ id: 'faqs.description' })}
          </h3>
          <div className="select">
            <Select />
          </div>
          <TabBox tabList={['Comercios', 'Personas']} tabContent={[tab1, tab2]} className='tab-box'></TabBox>
        </Column>
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  )
}
