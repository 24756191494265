import { forwardRef } from "react";
import styled from "styled-components";

const PeopleOfficeTitleRoot = styled.div``;

const PeopleOfficeTitleRootTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-63);
  line-height: 100%;
  letter-spacing: -0.0108em;
  color: var(--etpay-color-orion);
  margin-block-start: 0;
  margin-block-end: 124px;
  max-width: 1000px;

  @media screen and (max-width: 768px) {
    margin-block-end: 51px;
    font-size: var(--etpay-font-size-21);
    line-height: 114%;
    letter-spacing: -0.0008em;
  }
`;

const PeopleOfficeTitleRootText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-23);
  line-height: 124%;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);
  margin-block-start: 0;
  margin-block-end: 0;

  @media screen and (max-width: 768px) {
    font-size: var(--etpay-font-size-15);
    line-height: 120%;
  }
`;

export const PeopleOfficeTitle = (props) => {
  const { title, text } = props;

  return (
    <PeopleOfficeTitleRoot>
      <PeopleOfficeTitleRootTitle>{title}</PeopleOfficeTitleRootTitle>
      <PeopleOfficeTitleRootText>{text}</PeopleOfficeTitleRootText>
    </PeopleOfficeTitleRoot>
  );
};
