import { Column } from '..';
import './ConvertionBelow.scss';

export const ConvertionBelow = ({
  children,
  title,
  description,
  ...otherProps
}) => {
  return (
    <Column customClass="convertion-below" {...otherProps}>
      <div className="right">
        <h2 className="title">{title}</h2>
        <h3 className="description">{description}</h3>
      </div>
      <div className="left">{children}</div>
    </Column>
  );
};
