import React from 'react';

import { Column } from '..';
import './Banner.scss';

export const Banner = ({
  children,
  customClass,
  background,
  backgroundPosition,
  styles
}) => {
  return (
    <div
      className="banner-container--max"
    >
      <div className="banner-back-mesh">
        <img style={styles} className="banner-back-mesh-img" src={background} alt="" />
      </div>
      <div className={`banner-container ${customClass}`}>
        <>{children}</>
      </div>
    </div>
  );
};

export const Content = ({ title, subtitle, footer, customClass, content }) => (
  <div className={`content ${customClass}`}>
    {content}
    <div className="title">
      <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      <h3 dangerouslySetInnerHTML={{ __html: subtitle }} />
    </div>
    <div className="footer">{footer}</div>
  </div>
);
