import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import "./ReadyToStart.scss";
import { Icon, Button, Column } from "..";

export const ReadyToStart = ({ color, bgColor, ...otherProps }) => {
  const intl = useIntl();
  return (
    <Column
      customClass={"ready-to-start"}
      {...otherProps}
      style={{
        color: color,
        background: bgColor,
      }}
    >
      <div className="left">
        <h2 className="title" style={{ color: color }}>
          {intl.formatMessage({ id: "readyToStart.title" })}
        </h2>
        <div className="description" style={{ color: color }}>
          {intl.formatMessage({ id: "readyToStart.description" })}
        </div>
        <div className="buttons">
          <div>
            <a href={intl.formatMessage({
              id: "button.testFree.url",
            })}>
              <Button bgColor={"#72DBAA"} bgHoverColor="#140F33">
                <Icon name={"Icon_Pre"} className="fillable" />
                <span>{intl.formatMessage({ id: "button.testFree.text" })}</span>
              </Button>
            </a>
          </div>
          <div>
          <a href={
                'tel:'+intl.formatMessage({ id: "banner.home.button2.url" })
              }>
                <Button bgColor={"#F2F2F2"} bgHoverColor="#140F33">
                  <span>
                    {intl.formatMessage({ id: "banner.home.button2.text" })+intl.formatMessage({ id: "banner.home.button2.url" })}
                  </span>
                  <Icon name="icon-phone"></Icon>
                </Button>
              </a>
          </div>
        </div>
      </div>
      <div></div>

      <div className="right">
        <Icon className="sign" name="Dollar" color={color}></Icon>
        <h2 className="title" style={{ color: color }}>
          {intl.formatMessage({ id: "readyToStart.center.title" })}
        </h2>
        <h3 className="description" style={{ color: color }}>
          {intl.formatMessage({ id: "readyToStart.center.description" })}
        </h3>

        <Link to={intl.formatMessage({ id: "readyToStart.center.link.url" })}><div className="link" style={{ color: color }}>
          {intl.formatMessage({ id: "readyToStart.center.link" })}
          <Icon name="Icon_After" color={color}></Icon>
        </div></Link>
      </div>
      <div className="right">
        <Icon
          className="sign"
          name={`Timer${color === "white" ? "-white" : ""}`}
          color={color}
        ></Icon>
        <h2 className="title" style={{ color: color }}>
          {intl.formatMessage({ id: "readyToStart.right.title" })}
        </h2>
        <h3 className="description" style={{ color: color }}>
          {intl.formatMessage({ id: "readyToStart.right.description" })}
        </h3>

        <Link to={intl.formatMessage({ id: "easy_integration.home.button.url" })}><div className="link" style={{ color: color }}>
            {intl.formatMessage({ id: "readyToStart.right.link" })}
            <Icon name="Icon_After" color={color}></Icon>
          </div></Link>
      </div>
    </Column>
  );
};
