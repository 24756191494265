import { useIntl } from "react-intl";
import React from "react";
import ReactGA from "react-ga4";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  Icon,
  Banner,
  Content,
  Button,
  ReadyToStart,
  Column,
  IncludeProduct,
  PosibleWidthEtpay,
  InformationGroup,
  InformationGroupItem,
  Layout,
  List,
  ItemList,
  Tips,
} from "../../components";
import "./Returns.scss";
import useAppContext from "../../context/Context";
import cl from '../../banks/returns_CL.json'
import mx from '../../banks/returns_MX.json'

const color = "#5C2A48";

gsap.registerPlugin(ScrollTrigger);

export const Returns = () => {
  ReactGA.send({ hitType: "pageview", page: "/returns" });

  const intl = useIntl();
  const { getImages, activeCountry, setStep } = useAppContext();
  const [bankList, setBankList] = React.useState(null);

  React.useEffect(() => {
    setStep && setStep(1);
  }, []);

  const handleChangeBank = () => {
    activeCountry === 'cl'
      ? setBankList(cl)
      : setBankList(mx);
  }

  React.useEffect(handleChangeBank, [activeCountry])

  React.useEffect(() => {

    const w = window.innerWidth;
    const tlData = {
      trigger: ".section2",
      start: w > 1200 ? "top 20%" : "top top",
      end: "95% 40%",
      scrub: 1,
      pin: '.animation-container',
    };

    // back mobile
    gsap.timeline({
      scrollTrigger: {
        trigger: ".section2",
        start: "top top",
        end: "95% 40%",
        scrub: true,
        pin: '.animation-container-mobile',
      }
    })

    // circle 1
    const c1Center = '-150px';
    gsap.timeline({ scrollTrigger: tlData })
      .to(".circle-1", {
        transform: `translate(${c1Center}, 150px)`,
      })
      .to(".circle-1", {
        transform: `translate(${c1Center}, 50px)`,
      })
      .to(".circle-1", {
        transform: `translate(${c1Center}, 245px)`,
        borderRadius: "0",
        height: "32px",
      })
      .to(".circle-1", {
        zIndex: "3",
        transform: `translate(${c1Center}, 245px)`,
      })

    // circle 2
    const c2Center = '165';
    gsap.timeline({ scrollTrigger: tlData })
      .to(".circle-2", {
        transform: `translate(${c2Center}px,-150px)`,
      })
      .to(".circle-2", {
        width: "200px",
        height: "200px",
        transform: `translate(${c2Center - 25}px,-90px)`,
      })
      .to(".circle-2", {
        transform: `translate(${c2Center - 25}px, 0px)`,
        borderRadius: "0",
        height: "32px",
      })
      .to(".circle-2", {
        zIndex: "2",
        transform: `translate(${c2Center - 25}px, 25px)`,
      })

    // circle 3
    const c3Center = '445';
    gsap.timeline({ scrollTrigger: tlData })
      .to(".circle-3", {
        transform: `translate(${c3Center}px,-400px)`,
      })
      .to(".circle-3", {
        width: "90px",
        height: "90px",
        transform: `translate(${c3Center - 10}px,-235px)`,
      })
      .to(".circle-3", {
        transform: `translate(${c3Center - 10}px,-245px)`,
        borderRadius: "0",
        height: "32px",
      })
      .to(".circle-3", {
        zIndex: "1",
        transform: `translate(${c3Center - 10}px,-190px)`,
      })

    // circle white 1
    gsap.timeline({ scrollTrigger: tlData })
      .to(".circle-white", {
        transform: `scale(0)`,
      })
      .to(".circle-white", {
        transform: `scale(1)`,
      })
      .to(".circle-white", {
        transform: `scale(0)`,
      })
      .to(".circle-white", {
        transform: `scale(0)`,
      })

  }, []);

  const content = (
    <Layout>
      <Banner
        customClass="banner-quick"
        background={getImages["banner_background_returns"]}
      >

        <Content
          content={<div className="banner-top-icon">
            <div>
              <Icon name="Returns" />
            </div>
            <p>Devoluciones</p>
          </div>}
          title={intl.formatMessage({ id: "banner.returns.title" })}
          subtitle={intl.formatMessage({ id: "banner.returns.subtitle" })}
          footer={
            <>
              <a
                href={intl.formatMessage({
                  id: "button.testFree.url",
                })}
              >
                <Button bgColor={color} bgHoverColor="#140F33" color="#fff">
                  <Icon name={"Icon_Pre"} fill="#fff" />
                  <span>
                    {intl.formatMessage({ id: "button.testFree.text" })}
                  </span>
                </Button>
              </a>

              <a href={
                'tel:' + intl.formatMessage({ id: "banner.home.button2.url" })
              }>
                <Button bgColor={"#F2F2F2"} bgHoverColor="#140F33">
                  <span>
                    {intl.formatMessage({ id: "banner.home.button2.text" }) + intl.formatMessage({ id: "banner.home.button2.url" })}
                  </span>
                  <Icon name="icon-phone"></Icon>
                </Button>
              </a>
            </>
          }
          customClass="grid-5"
        />
        <div
          className="image-banner"
          style={{
            gridColumn: "7 / 13",
            marginTop: 0,
          }}
        >
          <img src={getImages["banner_image_returns"]} alt="" />
        </div>
      </Banner>

      <div className="returns-page">
        <Tips
          tip1={intl.formatMessage({ id: "returns.section1.t1" })}
          tip2={intl.formatMessage({ id: "returns.section1.t2" })}
          tip3={intl.formatMessage({ id: "returns.section1.t3" })}
        ></Tips>

        <Column customClass="section2">
          <div className="list">
            <div className="part">
              <h3 className="top-title red">
                {intl.formatMessage({ id: "returns.section2.t1" })}
              </h3>
              <h2 className="title">
                {intl.formatMessage({ id: "returns.section2.t2" })}
              </h2>
              <h4 className="sub-title">
                {intl.formatMessage({ id: "returns.section2.t3" })}
              </h4>
            </div>

            <div className="part">
              <h3 className="top-title">
                {intl.formatMessage({ id: "returns.section2.t4" })}
              </h3>
              <h4 className="sub-title">
                {intl.formatMessage({ id: "returns.section2.t5" })}
              </h4>
            </div>

            <div className="part">
              <h3 className="top-title">
                {intl.formatMessage({ id: "returns.section2.t6" })}
              </h3>
              <h4 className="sub-title">
                {intl.formatMessage({ id: "returns.section2.t7" })}
              </h4>

              <List>
                {bankList && Object.keys(bankList).map((bank) => (
                  !bank.includes("unchecked") &&
                  <ItemList key={bankList[bank]["name"]}>
                    <Icon name={bankList[bank]["icon"]} size="24px" />
                    <p>{bankList[bank]["name"]}</p>
                    {bankList[bank]["new"] === 'true' && <label>Proximamente</label>}
                  </ItemList>
                ))}
              </List>
            </div>

            <div className="part">
              <h3 className="top-title">
                {intl.formatMessage({ id: "returns.section2.t8" })}
              </h3>
              <h4 className="sub-title">
                {intl.formatMessage({ id: "returns.section2.t9" })}
              </h4>
            </div>

            <div className="part">
              <h3 className="top-title">
                {intl.formatMessage({ id: "returns.section2.t10" })}
              </h3>
              <h4 className="sub-title">
                {intl.formatMessage({ id: "returns.section2.t11" })}
              </h4>
            </div>
          </div>
          {/* desktop */}
          <div
            className="animation-container-mobile"
          ></div>
          <div className="animation-container">
            <div
              className="circle circle-1"
            ></div>

            <div
              className="circle circle-white circle-white-1"
            ></div>

            <div
              className="circle circle-2"
            ></div>

            <div
              className="circle circle-white circle-white-2"
            ></div>

            <div
              className="circle circle-3"
            ></div>

            <div
              className="circle circle-white circle-white-3"
            ></div>
          </div>
        </Column>

        <InformationGroup
          color={color}
          topTitle={intl.formatMessage({
            id: "returns.informationGroup.topTitle",
          })}
          title={intl.formatMessage({ id: "returns.informationGroup.title" })}
          description={intl.formatMessage({
            id: "returns.informationGroup.description",
          })}
        >
          <InformationGroupItem
            icon="Exclude"
            description={intl.formatMessage({
              id: "returns.informationGroup.item1.description",
            })}
            color={color}
          ></InformationGroupItem>
          <InformationGroupItem
            icon="ReadWrite"
            description={intl.formatMessage({
              id: "returns.informationGroup.item2.description",
            })}
            color={color}
          ></InformationGroupItem>
          <InformationGroupItem
            icon="Bank"
            description={intl.formatMessage({
              id: "returns.informationGroup.item3.description",
            })}
            color={color}
          ></InformationGroupItem>
        </InformationGroup>
        {/* <PosibleWidthEtpay color={color}></PosibleWidthEtpay> */}
        <IncludeProduct></IncludeProduct>
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  );

  return content;
};
