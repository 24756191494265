import React from "react";
import { Footer, Head } from "..";

export const Layout = ({ children, ...others }) => {
  return (
    <>
      <Head />
      <main {...others}>
        <>
          {children}
        </>
      </main>
      <Footer />
    </>
  );
};
