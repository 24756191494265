import './InformationGroupItem.scss';
import { Icon } from '..';

export const InformationGroupItem = ({
  children,
  color,
  icon,
  description,
  ...otherProps
}) => {
  return (
    <div className="information-group-item" {...otherProps}>
      <div className="icon">
        <Icon
          className="icon-item"
          size="52px"
          color={color}
          name={icon}
        ></Icon>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};
