import React from 'react'
import './Frame.scss'
import { useIntl } from 'react-intl'

export const Frame = ({ uri }) => {
    const intl = useIntl()
    return (
        <div className={`frame-container ${uri ? 'video-show' : ''}`}>
            {
                uri
                    ? <img style={{objectFit:'cover', width:'100%',height:'100%',borderRadius:'16px'}} src={uri}>
                    </img>
                    : <p>{intl.formatMessage({ id: 'frame.message' })}</p>
            }
        </div>
    )
}