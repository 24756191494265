import { useIntl } from "react-intl";

import { Button, Column, Icon, Layout } from '../../components';
import './PressBrand.scss';
import ReactGA from "react-ga4";

export const PressBand = () => {
  ReactGA.send({ hitType: "pageview", page: "/press-brand" });

  return (
    <Layout>
      <div className='press-brand'>
        <Column customClass='banner'>
          <img className='back' src="./Mesh-Grad4.svg" alt='' />
          <h1 className='title'>{useIntl().formatMessage({ id: 'pressBrand.banner.title' })}</h1>
          <h2 className='description'>{useIntl().formatMessage({ id: 'pressBrand.banner.description' })}</h2>
        </Column>
        <Column customClass='bottom-banner'>
          <h4 className='top-title'>{useIntl().formatMessage({ id: 'pressBrand.bottomBanner.topTitle' })}</h4>
          <h2 className='title'>{useIntl().formatMessage({ id: 'pressBrand.bottomBanner.title' })}</h2>
          <div className='sub-title'>
            {useIntl().formatMessage({ id: 'pressBrand.bottomBanner.subTitle' })}
            <span className="email">press@etpay.com</span>
          </div>
        </Column>
        <Column customClass='info'>
          <div className="item">
            <div className="icon">
              <img src="./df_mas1.svg" className="img" alt='' />
            </div>
            <div className="description">{useIntl().formatMessage({ id: 'pressBrand.item1.description' })}</div>
          </div>
          <div className="item">
            <div className="icon">
              <img src="./logo-diariofinanciero-21.svg" className="img" alt='' />
            </div>
            <div className="description">{useIntl().formatMessage({ id: 'pressBrand.item2.description' })}</div>
          </div>
          <div className="item">
            <div className="icon">
              <img src="./chocale-snippet-rrss1.svg" className="img" alt='' />
            </div>
            <div className="description">{useIntl().formatMessage({ id: 'pressBrand.item3.description' })}</div>
          </div>
        </Column>
        <Column customClass='marca'>
          <div className="left">
            <h4 className='top-title'>{useIntl().formatMessage({ id: 'pressBrand.marca.topTitle' })}</h4>
            <h2 className='title'>{useIntl().formatMessage({ id: 'pressBrand.marca.title' })}</h2>
            <h3 className='sub-title'>{useIntl().formatMessage({ id: 'pressBrand.marca.subTitle' })}</h3>
          </div>
          <div className="right">
            <div className="item">
              <img src="Etpay Logo.svg" className="img padding" alt="" />
              <div className="description">{useIntl().formatMessage({ id: 'pressBrand.marca.item1' })}</div>
              <div>
                {<a href="https://drive.google.com/drive/folders/1X5QiyqtVUbAOJ_C9rDYoYipCZCHH9KrU?usp=sharing"><Button bgColor='white'>
                  {useIntl().formatMessage({ id: 'pressBrand.marca.download' })}
                  <Icon name='Download'></Icon>
                </Button></a>}
              </div>
            </div>
            {/* <div className="item">
              <img src="Rectangle 1363.png" className="img" alt="" />
              <div className="description">{useIntl().formatMessage({ id: 'pressBrand.marca.item2' })}</div>
              <div>
              {<a href="https://drive.google.com/drive/folders/1X5QiyqtVUbAOJ_C9rDYoYipCZCHH9KrU?usp=sharing"><Button bgColor='white'>
                  {useIntl().formatMessage({ id: 'pressBrand.marca.download' })}
                  <Icon name='Download'></Icon>
                </Button></a>}
              </div>
            </div> */}
          </div>
        </Column>
      </div>
    </Layout>
  )
};
