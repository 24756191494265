import { Link } from "react-router-dom";
import { Layout } from '../../components'
import ReactGA from "react-ga4";

export const NotFound = () => {
  ReactGA.send({ hitType: "pageview", page: "/*" });

  return (
    <Layout>
      <main>
        <h2>No found</h2>
      </main>
      <nav>
        <a href="/">Home</a>
      </nav>
    </Layout>
  );
};
