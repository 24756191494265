import React from 'react'
import { Column } from '..'
import './SpecialSection.scss'
import isElementTotallyVisible from '../../services/detectElement'

export const SpecialSection = ({children}) => {
    const sectionRef = React.createRef(null)
    const [start, setStart] = React.useState(false)
    const divisor = window.innerHeight / 8;
    const [count, setCount] = React.useState(1)

    const handle = () => {
        setStart(isElementTotallyVisible(sectionRef))
        if(start){
            let box = sectionRef.current.getBoundingClientRect();
            if( (window.innerHeight - box.top) >= (divisor * count)){
                setCount(count + 1)
            }
            if( (window.innerHeight - box.top) <= (divisor * count)){
                setCount(count - 1)
            }

            switch(count){
                case 1: sectionRef.current.style.setProperty('--webkit-scale-1', `scaleX(1)`)
                break;
                case 2: sectionRef.current.style.setProperty('--webkit-scale-1', `scaleX(1)`)
                break;
                case 3: sectionRef.current.style.setProperty('--webkit-scale-1', `scaleX(1.02)`)
                break;
                case 4: sectionRef.current.style.setProperty('--webkit-scale-1', `scaleX(1.03)`)
                break;
                case 5: {
                    sectionRef.current.style.setProperty('--webkit-scale-1', `scaleX(1.05)`)
                    sectionRef.current.style.setProperty('--webkit-scale-1', `scaleX(1.05)`)
                }
                break;
            }
        }
    }

    React.useEffect(()=>{
        window.addEventListener('scroll', handle);
    
        return ()=>{
          window.removeEventListener('scroll', handle);
        }
    })

    return(
        <Column ref={sectionRef} customClass="special-section">
            { children }
        </Column>
    )
}