import { useIntl } from "react-intl";
import "./IncludeProduct.scss";
import { Icon, Column } from "..";
import { Link } from "react-router-dom";
import useAppContext from "../../context/Context";

const IconList1 = <Icon className="icon" name="Check3" size={18}></Icon>;
const IconList2 = <Icon className="icon" name="Check2" size={18}></Icon>;

export const IncludeProduct = ({ ...otherProps }) => {
  const { activeCountry } = useAppContext()

  const intl = useIntl();
  return (
    <Column customClass={"include-product"} {...otherProps}>
      <div className="back-container">
        <img
          className="back-container__img"
          src="banners/ETpay-grad_07_FC.jpg"
          alt=""
        />
      </div>

      <h4 className="tarifa">
        {intl.formatMessage({ id: "includeProduct.tarifa" })}
      </h4>
      <h2 className="title">
        {intl.formatMessage({ id: "includeProduct.title" })}
      </h2>
      <h3 className="description">
        {intl.formatMessage({ id: "includeProduct.description" })}
      </h3>

      <div className="separator"></div>

      {/* bottom 1 */}
      <div className="bottom-name">
        <div className="bottom-title">
          {intl.formatMessage({ id: "includeProduct.bottom1.title" })}
        </div>
        <a href="/quick-transfer">
          <div className="bottom-more">
            {intl.formatMessage({ id: "includeProduct.bottom1.more" })}
            <img src="/icons/Icon_After.svg" alt="" />
          </div>
        </a>
      </div>
      <div className="bottom-description">
        {intl.formatMessage({ id: "includeProduct.bottom1.description" })}
      </div>
      <div className="bottom-list">
        {/* item 1 */}
        <div className="bottom-list-item">
          {IconList1}
          {intl.formatMessage({ id: "includeProduct.bottom1.item1" })}
        </div>
        {/* item 2 */}
        <div className="bottom-list-item">
          {IconList1}
          {intl.formatMessage({ id: "includeProduct.bottom1.item2" })}
        </div>
        {/* item 3 */}
        <div className="bottom-list-item">
          {IconList1}
          {intl.formatMessage({ id: "includeProduct.bottom1.item3" })}
        </div>
      </div>

      {activeCountry==='mx'? '': <>
      <div className="bottom-separator"></div>

      {/* bottom 2 */}
      <div className="bottom-name">
        <div className="bottom-title">
          {intl.formatMessage({ id: "includeProduct.bottom2.title" })}
        </div>
        <a href="/payment-links">
          <div className="bottom-more">
            {intl.formatMessage({ id: "includeProduct.bottom2.more" })}
            <img src="/icons/Icon_After.svg" alt="" />
          </div>
        </a>
      </div>
      <div className="bottom-description">
        {intl.formatMessage({ id: "includeProduct.bottom2.description" })}
      </div>
      <div className="bottom-list">
        {/* item 1 */}
        <div className="bottom-list-item">
          {IconList2}
          {intl.formatMessage({ id: "includeProduct.bottom2.item1" })}
        </div>
        {/* item 2 */}
        <div className="bottom-list-item">
          {IconList2}
          {intl.formatMessage({ id: "includeProduct.bottom2.item2" })}
        </div>
        {/* item 3 */}
        <div className="bottom-list-item">
          {IconList2}
          {intl.formatMessage({ id: "includeProduct.bottom2.item3" })}
        </div>
      </div>
      </>}
    </Column>
  );
};
