import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useAppContext from '../../context/Context';
import home_CL from '../../banks/home_CL.json'
import home_MX from '../../banks/home_MX.json'

import './BankInformation.scss';

export const BankInformation = () => {
  const { activeCountry } = useAppContext();
  const [bankList, setBankList] = useState(null); 

  const handleChangeBank = () => {
    activeCountry === 'cl' 
    ? setBankList(home_CL)
    : setBankList(home_MX);
  }

  useEffect(handleChangeBank,[activeCountry])
  
  useEffect(() => {
    document
      .querySelector('.bank-information__list')
      .addEventListener('scroll', () => {
        if (document.querySelector('.bank-information__list').scrollTop === 0) {
          document
            .querySelector('.bank-information .deg.top')
            .classList.add('hidden');
        } else if (
          document.querySelector('.bank-information__list').scrollTop ===
          document.querySelector('.bank-information__list').scrollHeight -
            document.querySelector('.bank-information__list').clientHeight
        ) {
          document
            .querySelector('.bank-information .deg.bottom')
            .classList.add('hidden');
        } else {
          document
            .querySelector('.bank-information .deg.bottom')
            .classList.remove('hidden');
          document
            .querySelector('.bank-information .deg.top')
            .classList.remove('hidden');
        }
      });
  });
  return (
    <div className="bank-information">
      <div className="title">
        {useIntl().formatMessage({ id: 'bankInformation.title' })}
      </div>
      <div className="description">
        {useIntl().formatMessage({ id: 'bankInformation.description' })}
      </div>
      <div className="deg top"></div>
      <div className="bank-information__list">
        {
          bankList &&
          Object.keys(bankList).map((bank,i) =>(
            <div className="item" key={i}>
              { bankList[bank] }
            </div>
          ))
        }       
      </div>
      <div className="deg bottom"></div>
    </div>
  );
};
