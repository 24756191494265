import { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/Icon';

const ButtonSliderRoot = styled.div`
  @media (max-width: 768px) {
    font-size: var(--etpay-font-size-08);
    font-weight: 400;
    height: 47px;
    max-width: 269px;
  }
  height: 65px;
  width: 100%;
  overflow: hidden;
  max-width: 500px;
  border-radius: 24px;
  border: 1px solid var(--etpay-color-sirus);
`;

const ButtonSliderSlider = styled.div`
  white-space: nowrap;
  height: 100%;
  transition: ease 1000ms;
  border-radius: 24px;
  position: var(--etpay-position-relative);
`;

const ButtonSliderItem = styled.div`
  position: var(--etpay-position-absolute);
  right: 0;
  height: 100%;
  width: ${(props) => `${props.width}`};
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  transition: ease 1000ms;

  &:hover {
    cursor: pointer;
  }

  &.variant-blue {
    background: var(--etpay-color-orion);
    & div {
      color: var(--etpay-color-sirus);  
    }
  }

  &.variant-white {
    transform: translateX(100%);
    background: var(--etpay-color-sirus);
    color: var(--etpay-color-orion);

    svg path {
      stroke: var(--etpay-color-orion);
      fill: var(--etpay-color-orion);
    }

    &.active {
      animation: slide 3000ms ease-in-out;
    }
  }

  @keyframes slide {
    30% {
      transform: translateX(0);
    }
    60% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const ButtonSliderItemContent = styled.div`
  @media (max-width: 768px) {
    margin: 10px;
  }
  margin: 10px 24px;
  height: calc(100% - 20px);
  display: var(--etpay-display-flex);
  align-items: center;
  justify-content: flex-start;
  flex-direction: var(--etpay-flex-direction-row);
`;

const ButtonSliderItemIcon = styled.div`
  height: 38px
  width: 38px;
  margin-right: 12px;
`;

const ButtonSliderItemText = styled.div`
  width: 100%;
  display: var(--etpay-display-flex);
  align-items: center;
  justify-content: space-between;
`;

export const ButtonSlider = memo(
  ({ items, delay = 6000, show = 1, infiniteLoop = true }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [paused, setPause] = useState(false);
    const timeoutRef = useRef(null);
    const [touchPosition, setTouchPosition] = useState(null);
    const [isRepeating, setIsRepeating] = useState(items.length > show);

    const reset = useCallback(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }, [timeoutRef]);

    const next = () => {
      if (isRepeating && currentIndex < items.length - show) {
        setCurrentIndex((prevState) => prevState + 1);
      }
    };

    const prev = () => {
      if (isRepeating && currentIndex > 0) {
        setCurrentIndex((prevState) => prevState - 1);
      }
    };

    const handleTouchStart = (e) => {
      const touchDown = e.touches[0].clientX;
      setTouchPosition(touchDown);
    };

    const handleTouchMove = (e) => {
      const touchDown = touchPosition;

      if (touchDown === null) {
        return;
      }

      const currentTouch = e.touches[0].clientX;
      const diff = touchDown - currentTouch;

      if (diff > 5) {
        next();
      }

      if (diff < -5) {
        prev();
      }

      setTouchPosition(null);
    };

    const togglePause = useCallback(() => {
      setPause(!paused);
    }, [paused]);

    useEffect(() => {
      reset();

      setIsRepeating(infiniteLoop && items.length > show);

      if (items.length > 1)
        timeoutRef.current = setTimeout(() => {
          if (!paused) {
            setCurrentIndex((prevIndex) =>
              prevIndex === items.length - 1 ? 0 : prevIndex + 1
            );
          }
        }, delay);

      return () => {
        reset();
      };
    }, [delay, currentIndex, items, paused, reset, show, infiniteLoop]);

    return (
      <ButtonSliderRoot
        onClick={togglePause}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <ButtonSliderSlider>
          {items.map((item, index) => (
            <ButtonSliderItem
              className={`button-slider-item variant-${item.variant} ${
                currentIndex === index && item.variant === 'white' && 'active'
              }`}
              key={index}
              width={`${(1 / show) * 100}%`}
              // style={
              //   currentIndex === index
              //     ? {
              //         transform: `translateX(${-currentIndex * (100 / show)}%)`,
              //       }
              //     : {}
              // }
            >
              <ButtonSliderItemContent>
                {item.icon && (
                  <ButtonSliderItemIcon>
                    <Icon name={item.icon} />
                  </ButtonSliderItemIcon>
                )}
                <ButtonSliderItemText>
                  {item.text && item.text}
                  {item.child && item.child}
                </ButtonSliderItemText>
              </ButtonSliderItemContent>
            </ButtonSliderItem>
          ))}
        </ButtonSliderSlider>
      </ButtonSliderRoot>
    );
  }
);
