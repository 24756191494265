import React from 'react'
import './CardIcon.scss'
import { useIntl } from 'react-intl'
import { Icon } from '..'
import isElementTotallyVisible from '../../services/detectElement'

export const CardIcon = ({icon, title, text, index, ...otherProps}) =>{
    const intl = useIntl()
    const cardRef = React.createRef(null)
    const [active,setActive] = React.useState(false)

    const handleTriggerScroll = () =>{
        setActive(isElementTotallyVisible(cardRef));
    }

    React.useEffect(()=>{
        window.addEventListener('scroll', handleTriggerScroll);
    
        return ()=>{
          window.removeEventListener('scroll', handleTriggerScroll);
        }
    })

    return(
        <div ref={cardRef} className={`card-icon item__${index} ${active ? `transform__card` : ''}`} {...otherProps}>
            <Icon name={icon} />
            <h3>{intl.formatMessage({ id: title })}</h3>
            <p>{intl.formatMessage({ id: text })}</p>
        </div>
    )
}