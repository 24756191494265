import React from 'react'
import cl from '../images/cl.json'
import mx from '../images/mx.json'
import { useCurrentLocationInfo } from "../hooks";

//Default state
const defaultState = {
  step: null,
  setStep: null,
  countries: null,
  activeCountry: 'cl',
  setActiveCountry: null,
  language: null,
  images: null,
  getImages: null,
}

//Context
export const AppContext = React.createContext(defaultState);

//Provider
export const AppContextProvider = ({ children }) => {
  const { data } = useCurrentLocationInfo();
  const [step, setStep] = React.useState(1)
  const countries = [
    { text: 'country.chile', value: 'cl' },
    { text: 'country.mexico', value: 'mx' }
  ]



  const country = data !== undefined ?
    data.hasOwnProperty("countryCode") ? data.countryCode.toLowerCase() : 'cl'
    : 'cl';

  const [activeCountry, setActiveCountry] = React.useState(country)
  React.useEffect(() => {
    setActiveCountry(country !=='mx'?'cl':country)
  }, [data]);

  const language = activeCountry
  const images = {
    cl: cl,
    mx: mx,
  };
  const getImages = images[activeCountry]

  const values = React.useMemo(() => (
    {
      step,
      setStep,
      countries,
      activeCountry,
      setActiveCountry,
      getImages
    }),
    [step, countries, activeCountry, language, getImages])  // States que serán visibles en el contexto.

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

//
export function useAppContext() {
  const context = React.useContext(AppContext);

  if (!context) {
    console.error('Error deploying App Context!!!');
  }

  return context;
}

export default useAppContext;