import { memo, useEffect } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Select } from "../Select/Select";
import useAppContext from "../../context/Context";

const BrandListRoot = styled.div`
  display: var(--etpay-display-flex);
  align-items: center;
  justify-content: center;
  flex-direction: var(--etpay-flex-direction-column);
`;

const BrandListHeader = styled.div`
  @media (max-width: 1200px) {
    flex-direction: var(--etpay-flex-direction-column);
  }
  display: var(--etpay-display-flex);
  align-items: center;
  justify-content: center;
  flex-direction: var(--etpay-flex-direction-row);
  margin-bottom: 50px;

  h2 {
    @media (max-width: 1200px) {
      display: inline-block;
      margin-right: 0;
    }
    font-family: "Oatmeal Pro";
    font-style: normal;
    font-weight: 400;
    font-size: var(--etpay-font-size-10);
    line-height: 24px;
    letter-spacing: -0.0008em;

    /* ETpay/Orion */

    color: var(--etpay-color-orion);

    margin-block-start: 0;
    margin-block-end: 0;
    margin-right: 10px;
  }
`;

const BrandListImages = styled.div`
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  display: var(--etpay-display-flex);
  align-items: center;
  justify-content: center;
  flex-direction: var(--etpay-flex-direction-row);
  flex-wrap: wrap;
`;

const BrandListItem = styled.img`
  @media (max-width: 1200px) {
    margin: 32px auto;
  }
  margin-right: 68px;
  margin-bottom: 32px;
`;

export const BrandList = memo(({ brandsGroup, initialBrand, ...others }) => {
  const intl = useIntl();
  const { countries, activeCountry } = useAppContext()

  return (
    <BrandListRoot {...others}>
      <BrandListHeader>
        <h2>{intl.formatMessage({ id: "brandList.title" })}</h2>
        <div>
          <Select />
        </div>
      </BrandListHeader>
      {activeCountry === 'mx' ? '' : <BrandListImages>
        {brandsGroup[activeCountry].map((brand) => (
          <BrandListItem key={brand} src={brand} />
        ))}
      </BrandListImages>}
    </BrandListRoot>
  );
});