import React from "react";
import { useIntl } from "react-intl";
import { Link, useMatch } from "react-router-dom";

import { Icon } from "..";
import "./Footer.scss";
import useAppContext from "../../context/Context";

export const Footer = () => {
  const intl = useIntl();
  const match = useMatch("developers");
  const { countries, activeCountry } = useAppContext()

  return (
    <footer className={`footer-page ${match && "developer-page"}`}>
      {/* content */}
      <div className="layout-container flex-grid">
        <div className="flex-grid__cols-12 flex-grid__mobile__cols-6">
          <div className="flex-grid footer-content">
            <div className="flex-grid__cols-6 flex-grid__mobile__cols-6">
              <div className="flex-grid">
                <div className="flex-grid__cols-6 flex-grid__mobile__cols-6">
                  <div className="locate">
                    <Icon name="UFO" size="25px" className="etpayLogo" />
                    <p>
                      {intl.formatMessage({
                        id: "footer.address.text",
                      })}
                    </p>
                    <div className="icons">
                      <a href="https://www.linkedin.com/company/etpay">
                        <Icon name="Linkedin" size="25px" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-grid__cols-6 flex-grid__mobile__cols-6">
              <div className="flex-grid">
                <div className="flex-grid__cols-4 flex-grid__mobile__cols-6">
                  <div className="menu-link">
                    <div>
                      <h3>
                        {intl.formatMessage({
                          id: "footer.products.title",
                        })}
                      </h3>
                      <ul>
                        <li>
                          <Link to='/quick-transfer'>{intl.formatMessage({
                            id: "footer.products.menu.quickTransfer.title",
                          })}</Link>
                        </li>
                        {activeCountry === 'mx' ? '' : <li>
                          <Link to='/payment-links'>{intl.formatMessage({
                            id: "footer.products.menu.paymentLinks.title",
                          })}</Link>
                        </li>}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flex-grid__cols-4 flex-grid__mobile__cols-6">
                  <div className="menu-link">
                    <div>
                      <h3>
                        {intl.formatMessage({
                          id: "footer.services.title",
                        })}
                      </h3>
                      <ul>
                        <li>
                          <Link to='/conciliation'>{intl.formatMessage({
                            id: "footer.services.menu.conciliation.title",
                          })}</Link>
                        </li>
                        {activeCountry === 'mx' ? '' : <li>
                          <Link to='/returns'>{intl.formatMessage({
                            id: "footer.services.menu.returns.title",
                          })}</Link>
                        </li>}
                        <li>
                          <a href="http://status.etpay.com">
                            {intl.formatMessage({
                              id: "footer.services.menu.system.title",
                            })}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="flex-grid__cols-4 flex-grid__mobile__cols-6">
                  <div className="menu-link">
                    <div>
                      <h3>
                        {intl.formatMessage({
                          id: "footer.about.title",
                        })}
                      </h3>
                      <ul>
                        <li>
                          <Link to='/people-office'>{intl.formatMessage({
                            id: "footer.about.team.title",
                          })}</Link>
                        </li>
                        <li>
                          <Link to='/press-brand'>{intl.formatMessage({
                            id: "footer.about.brand.title",
                          })}</Link>
                          <a href="/">

                          </a>
                        </li>
                        <li>

                          <Link to='/uses-cases'>{intl.formatMessage({
                            id: "footer.about.useCases.title",
                          })}</Link>
                        </li>
                        <li>
                          <Link to='/faqs'>{intl.formatMessage({
                            id: "footer.about.faq.title",
                          })}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grid__cols-12 flex-grid__mobile__cols-6">
          <div className="divider"></div>
        </div>

        <div className="flex-grid__cols-6 flex-grid__mobile__cols-2">
          <div className="terms">
            <p>&#169; {new Date().getFullYear()} ETpay</p>
          </div>
        </div>

        <div className="flex-grid__cols-6 flex-grid__mobile__cols-4 copyright">
          <div className="flex-grid">
            <div className="flex-grid__cols-4 flex-grid__mobile__cols-6">
              <Link to='/privacy-policy'>{intl.formatMessage({
                id: "footer.terms.privacy.text",
              })}</Link>
            </div>
            <div className="flex-grid__cols-4 flex-grid__mobile__cols-6">
            <Link to='/terms-conditions'>{intl.formatMessage({
                  id: "footer.terms.text",
                })}</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
