import { React } from "react";
import styled from "styled-components";

const ImageLayout = styled.div`
  position: var(--etpay-position-absolute);
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  z-index: -1;
  ${'' /* opacity: 0.2; */}
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const ImageContent = styled.img`
  height: 100%;
  width: 100%
`;

export const PositionedImage = ({
  image,
  top,
  right,
  bottom,
  left,
  width,
  height,
}) => (
  <ImageLayout
    top={top}
    right={right}
    bottom={bottom}
    left={left}
    image={image}
    width={width}
    height={height}
  >
    <ImageContent src={image}></ImageContent>
  </ImageLayout>
);
