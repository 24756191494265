import React from "react";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { AppContextProvider } from "./context/Context";

export const Root = () => {  
  return (
    <AppContextProvider>      
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </AppContextProvider>
  );
};
