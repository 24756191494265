import { useState } from 'react';

import { Button } from '..';
import './TabBox.scss';

export const TabBox = ({ children, tabList, tabContent, ...otherProps }) => {
  let [position, setPosition] = useState(0);
  //setPosition(0);
  return (
    <div className="tab-box">
      <div className="tabs-content">
        <div className="tabs">
          {tabList.map((tab, i) => (
            <Button
              bgColor={'rgba(0, 0, 0, 0)'}
              onHandleClick={() => setPosition(i)}
              className={`tab ${position === i ? 'active' : ''}`}
            >
              {tab}
            </Button>
          ))}
        </div>
      </div>
      <div className="content">
        {tabContent.map((content, i) => {
          return position === i && content;
        })}
      </div>
    </div>
  );
};
