import { useIntl } from "react-intl";
import {
  Button,
  Icon,
  Column,
  ReadyToStart,
  Layout,
  Console,
  AnimateIcon,
  List,
  ItemList,
} from "../../components";
import { paymentLink, quickTransfer } from "../../services/animationIcons";
import "./Developers.scss";
import ReactGA from "react-ga4";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

const ecom = [
  {
    icon: "Check",
    name: "Magento",
    new: false,
  },
  {
    icon: "Check",
    name: "Prestashop",
    new: false,
  },
  {
    icon: "Check",
    name: "Vtex",
    new: false,
  },
  {
    icon: "Check",
    name: "WooComerce",
    new: false,
  },
];

export const Developers = () => {
  ReactGA.send({ hitType: "pageview", page: "/developers" });

  useEffect(() => {
    const tlData = {
      trigger: ".section2",
      start: "top 150px",
      end: "90% 40%",
      scrub: 1,
      pin: '.animation',
    };

    // back 
    gsap.timeline({ scrollTrigger: tlData })
      .to(".web-back", {
        clipPath: 'polygon(54% 13%, 84% 35%, 73% 69%, 22% 47%)'
      })

    // back circle
    gsap.timeline({ scrollTrigger: tlData })
      .to(".web-back-circle", {
        transform: "translate(-110px, -110px) scale(1)",
      })

    // dot 2
    gsap.timeline({ scrollTrigger: tlData })
      .to(".dot-2", {
        transform: "translate(-5px,-132px) scale(0.5)"
      })

    // dot 3
    gsap.timeline({ scrollTrigger: tlData })
      .to(".dot-3", {
        transform: "translate(115px,-41px) scale(0.5)",
      })
    // dot 4
    gsap.timeline({ scrollTrigger: tlData })
      .to(".dot-4", {
        transform: "translate(71px,92px) scale(0.5)",
      })
    // dot 5
    gsap.timeline({ scrollTrigger: tlData })
      .to(".dot-5", {
        transform: "translate(-132px,5px) scale(0.5)",
      })

    // line 1
    gsap.timeline({ scrollTrigger: tlData })
      .to(".line-1", {
        transform: 'translate(20px,20px) rotate(-19deg)',
        width: "0px",
      })

    // line 2
    gsap.timeline({ scrollTrigger: tlData })
      .to(".line-2", {
        transform: 'translate(20px,20px) rotate(-92deg)',
        width: "0px",
      })

    // line 3
    gsap.timeline({ scrollTrigger: tlData })
      .to(".line-3", {
        transform: 'translate(20px,20px) rotate(-182deg)',
        width: "0px",
      })

    // line 4
    gsap.timeline({ scrollTrigger: tlData })
      .to(".line-4", {
        transform: 'translate(20px,20px) rotate(70deg)',
        width: "0px",
      })

  }, []);
  return (
    <Layout>
      <div className="developers-page">
        <Column customClass="banner">
          <img className="img-figure" src="Mesh-Grad2.svg" alt="" />
          <img className="img-texture" src="Grain-Texture2.svg" alt="" />
          <h1 className="title">
            {useIntl().formatMessage({ id: "developers.banner.title" })}
          </h1>
          <h2 className="description">
            {useIntl().formatMessage({ id: "developers.banner.description" })}
          </h2>
          <a
            className="link"
            href={useIntl().formatMessage({ id: "goToDocumentation.link" })}
          >
            {/* <Button>
            {useIntl().formatMessage({ id: "goToDocumentation.text" })}
            <Icon name={"Icon_After"} />
          </Button> */}
          </a>
        </Column>
        <Column customClass="section1">
          <Console className="console"></Console>
          <h4 className="performance">
            {useIntl().formatMessage({ id: "developers.section1.performance" })}
          </h4>
          <h2 className="title">
            {useIntl().formatMessage({ id: "developers.section1.title" })}
          </h2>
          <h3 className="description">
            {useIntl().formatMessage({ id: "developers.section1.description" })}
          </h3>
        </Column>
        <Column customClass="section2">
          <div className="img-figure">
            <img src="Mesh-Grad3.svg" alt="" />
          </div>
          <div className="list">
            <div className="part">
              <h3 className="top-title">
                {useIntl().formatMessage({ id: "developers.section2.t1" })}
              </h3>
              <h4 className="sub-title">
                {useIntl().formatMessage({ id: "developers.section2.t2" })}
              </h4>
              <List customClass="bank-list">
                {ecom.map((e) => (
                  <ItemList>
                    <Icon name={e.icon} size="24px" />
                    <p className="name">{e.name}</p>
                    {e.new && <label className="new">Proximamente</label>}
                  </ItemList>
                ))}
              </List>
            </div>
            <div className="part">
              <h3 className="top-title">
                {useIntl().formatMessage({ id: "developers.section2.t3" })}
              </h3>
              <h4 className="sub-title">
                {useIntl().formatMessage({ id: "developers.section2.t4" })}
              </h4>
            </div>
          </div>

          {/* desktop */}
          <div className="animation">
            <div
              className="web"
            >
              <div
                className="web-line line-1"
              ></div>
              <div
                className="web-line line-2"
              ></div>
              <div
                className="web-line line-3"
              ></div>
              <div
                className="web-line line-4"
              ></div>

              <div className="web-back"></div>
              <div className="web-back-circle"></div>

              <div className="web-dot dot-1"></div>
              <div
                className="web-dot dot-2"
              ></div>
              <div
                className="web-dot dot-3"
              ></div>
              <div
                className="web-dot dot-4"
              ></div>
              <div
                className="web-dot dot-5"
              ></div>
            </div>
          </div>
        </Column>
        {/* <Column customClass="section3">
          <h4 className="top-title">
            {useIntl().formatMessage({ id: "developers.section3.topTitle" })}
          </h4>
          <h2 className="title">
            {useIntl().formatMessage({ id: "developers.section3.title" })}
          </h2>
          <h3 className="description">
            {useIntl().formatMessage({ id: "developers.section3.description" })}
          </h3>
          <div className="item first">
            <div className="icon">
              <AnimateIcon json={quickTransfer} size="3.5rem" />
            </div>
            <div className="content">
              <h3 className="title">
                {useIntl().formatMessage({
                  id: "developers.section3.item1.title",
                })}
              </h3>
              <h5 className="description">
                {useIntl().formatMessage({
                  id: "developers.section3.item1.description",
                })}
              </h5>
              <a
                className="link"
                href={useIntl().formatMessage({
                  id: "goToDocumentation.link",
                })}
              >
                {useIntl().formatMessage({
                  id: "goToDocumentation.text",
                })}
                <Icon name="Icon_After" color="white" />
              </a>
            </div>
          </div>
          <div className="item second">
            <div className="icon">
              <AnimateIcon json={paymentLink} size="3.5rem" />
            </div>
            <div className="content">
              <h3 className="title">
                {useIntl().formatMessage({
                  id: "developers.section3.item2.title",
                })}
              </h3>
              <h5 className="description">
                {useIntl().formatMessage({
                  id: "developers.section3.item2.description",
                })}
              </h5>
              <a
                className="link"
                href={useIntl().formatMessage({
                  id: "goToDocumentation.link",
                })}
              >
                {useIntl().formatMessage({
                  id: "goToDocumentation.text",
                })}
                <Icon name="Icon_After" color="white" />
              </a>
            </div>
          </div>
        </Column> */}
        <ReadyToStart color="white"></ReadyToStart>
      </div>
    </Layout>
  )
};
