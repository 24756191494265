import { React, useEffect, useRef, useState } from "react";
import { DevLayout } from "../Dev/DevLayout";
import "./Index.scss";

export const Index = ({ children, offset = 10 }) => {
  const menu = [];
  const navEl = useRef(null);
  const [navHeight, setNavHeight] = useState(0);

  const handleClick = (index) => {
    const offsetTop = children[index]?.ref?.current.offsetTop || 0;
    window.scrollTo({
      top: offsetTop - navHeight - offset,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute("id");
        if (entry.intersectionRatio > 0) {
          document
            .querySelector(
              `.index_container__navigation_menu nav div a[href="#${id}"]`
            )
            .parentElement.classList.add("active");
        } else {
          document
            .querySelector(
              `.index_container__navigation_menu nav div a[href="#${id}"]`
            )
            .parentElement.classList.remove("active");
        }
      });
    });

    // Track all sections that have an `id` applied
    document
      .querySelectorAll(".index_container__content section[id]")
      .forEach((section) => {
        observer.observe(section);
      });

    setNavHeight(navEl?.current?.clientHeight || 0);

    return () => {
      observer.disconnect();
    };
  }, []);

  if (typeof children === "object" && children.length) {
    children.forEach((element) => {
      if (
        element.hasOwnProperty("props") &&
        element.props.hasOwnProperty("title")
      ) {
        menu.push({
          title: element.props.title,
          sectionKey: element.props.sectionKey,
        });
      }
    });
  }

  return (
    <div className="index_container" ref={navEl}>
      {/* <DevLayout /> */}
      <div className="index_container__navigation_menu">
        <nav
          className="index_container__navigation_menu__content"
          style={{
            top: '120px',
          }}
        >
          {menu.map((section, index) => (
            <div
              key={`${section.title}${index}`}
              id={`${section.title}${index}`}
              onClick={() => handleClick(index)}
              className="index_container__navigation_menu__content__item"
            >
              <a
                className="index_container__navigation_menu__content__item__text"
                href={`#section-id-${section.sectionKey}`}
              >
                {section.title}
              </a>
            </div>
          ))}
        </nav>
      </div>
      <div className="index_container__content">{children}</div>
    </div>
  );
};

export const IndexSection = ({ title, sectionKey, children }) => {
  const ref = useRef(null);
  return (
    <section
      className="index_container__content__section"
      id={`section-id-${sectionKey}`}
      ref={ref}
    >
      <h2>{title}</h2>
      {children}
    </section>
  );
};

export const IndexSubSection = ({ title, sectionKey, children }) => {
  const ref = useRef(null);
  return (
    <section
      className="index_container__content__sub_section"
      id={`section-id-${sectionKey}`}
      ref={ref}
    >
      {children}
    </section>
  );
};
