import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

const StyledSVGIcon = styled(ReactSVG)`
  div {
    ${({ size }) =>
      size &&
      css`
        width: ${size};
        height: ${size};
      `}
    display: var(--etpay-display-flex);
    align-items: center;
    justify-content: center;
  }
  svg {
    ${({ size }) =>
      size &&
      css`
        width: ${size};
        height: ${size};
      `}
    path {
      ${({ color }) =>
        color &&
        css`
          stroke: ${color};
          transition: all 0.2s ease;
        `}
        ${({ fill }) =>
          fill &&
          css`
            fill: ${fill};
            transition: all 0.2s ease;
          `}
    }

    &:hover {
      cursor: pointer;
      path {
        ${({ hoverColor }) =>
          css`
            stroke: ${hoverColor};
            fill: ${hoverColor} !important;
          `}
        }
      }
    }
  }
`;

export const Icon = ({
  name,
  size,
  color,
  fill,
  hoverColor = undefined,
  styles,
  onHandleClick = () => {},
  ...otherProps
}) => {
  return (
    <StyledSVGIcon
      src={`/icons/${name}.svg`}
      size={size}
      color={color}
      fill={fill}
      hoverColor={hoverColor}
      onClick={onHandleClick}
      {...otherProps}
    />
  );
};
