import { React } from "react";
import styled from "styled-components";

const PeopleOfficeTeamPhotoRoot = styled.div`
  > img:not(:first-child) {
    display: none;
  }

  @media (max-width: 768px) {
    > img:not(:first-child) {
      display: block;
    }
  }
`;

const PeopleOfficeTeamPhotoRootPhoto = styled.img`
  width: min(90%, 906px);
  height: min(auto, 607px);
  border-radius: 34px;

  @media (max-width: 768px) {
    width: min(100%, 346px);
    height: min(auto, 232px);
  }
`;

export const PeopleOfficeTeamPhoto = ({ photos }) => (
  <PeopleOfficeTeamPhotoRoot>
    {photos.map((photo) => (
      <PeopleOfficeTeamPhotoRootPhoto key={photo} src={photo} />
    ))}
  </PeopleOfficeTeamPhotoRoot>
);
