import React from 'react';
import './EasyIntegration.scss';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

export const EasyIntegration = ({ children, customClasses, ...otherProps }) => {
  const location = useLocation();
  const intl = useIntl();
  return (
    <div className={`easy-integration ${customClasses}`} {...otherProps}>
      <h3 className="tag">
        {intl.formatMessage({
          id: `easy_integration.${location.pathname}.tag`,
        })}
      </h3>
      <h1 className="title">
        {intl.formatMessage({ id: 'easy_integration.title' })}
      </h1>
      <h3
        className="subtitle"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: `easy_integration.${location.pathname}.subtitle`,
          }),
        }}
      />
      {children}
    </div>
  );
};
