import { memo, React, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const BankTypeRoot = styled.div`
  /* ETpay / Sirius */

  background: var(--etpay-color-sirus);
  /* LongDrop */

  box-shadow: 2px 0px 34px rgba(88, 87, 87, 0.14);
  border-radius: 24px;
`;

const BankTypeHeader = styled.div`
  padding: 26px 38px;
  /* Web Desktop/Title 2 */

  font-family: 'Oatmeal Pro';
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-18);
  line-height: 120%;
  /* or 48px */

  letter-spacing: -0.0008em;

  /* ETpay/Orion */

  color: var(--etpay-color-orion);
`;

const BankTypeList = styled.div`
  position: var(--etpay-position-relative);
  overflow: hidden;
  z-index: 3;
`;

const BankTypeListContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 57px;
  overflow: hidden;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 3;
`;

const BankTypeListVector = styled.div`
  position: absolute;
  top: -80px;
  left: 0;
  width: 370px;
  height: 370px;
  border-radius: 100%;
  border: 4px solid #7de5b5;
  clip-path: polygon(50% 50%, -10% 50%, -10% -10%, 110% -10%, 110% 50%);
  animation: vector 4s linear infinite;
  transform: rotate(0.5turn);
  z-index: -10;

  @keyframes vector {
    100% {
      transform: rotate(1.5turn);
    }
  }
`;

const BankTypeListVector2 = styled.div`
  position: absolute;
  top: -80px;
  left: 0;
  width: 370px;
  height: 370px;
  border-radius: 100%;
  border: 4px solid var(--etpay-color-purple);
  clip-path: polygon(50% 50%, -10% 50%, -10% -10%, 110% -10%, 110% 50%);
  animation: vector 4s 3s linear infinite;
  transform: rotate(0.5turn);
  z-index: -10;

  @keyframes vector {
    100% {
      transform: rotate(1.5turn);
    }
  }
`;
const BankTypeListVectorTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fcfcfc;
  z-index: -1;
`;

const BankTypeListContainerTrack = styled.div`
  animation: scroll 20s linear infinite;
  display: var(--etpay-display-flex);
  width: 0;

  @keyframes scroll {
    100% {
      transform: translateX(calc(-94px * ${(props) => props.size - 1}));
    }
  }
`;

const BankTypeListItem = styled.div`
  width: 94px;
  height: 82px;
  position: relative;
  z-index: 30;
`;

const BankTypeListItemImage = styled.img`
  width: 82px;
  height: 82px;
  margin-right: 12px;
  border-radius: 11.7143px;
  z-index: 3;
`;

export const BankTypes = memo(({ banksGroup, initialBank }) => {
  const intl = useIntl();
  let countries = Object.keys(banksGroup);
  const [activeCountry, setActiveCountry] = useState(
    countries.length > 0 ? countries[0] : initialBank
  );
  let isSafari = false;

  const userAgent = window.navigator.userAgent.toLowerCase()
  const ios = /iphone|ipod|ipad|crios|fxios|safari/.test(userAgent)
  const chrome = /chrome/.test(userAgent)

  if (ios && !chrome) {
    isSafari = true
  }
  return (
    <BankTypeRoot>
      <BankTypeHeader
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'bankType.headerText',
          }),
        }}
      ></BankTypeHeader>
      <BankTypeHeader></BankTypeHeader>
      <BankTypeList>
        <BankTypeListContainer>
          <BankTypeListContainerTrack
            size={
              banksGroup && banksGroup[activeCountry]
                ? banksGroup[activeCountry].length + 1
                : 0
            }
          >
            {banksGroup &&
              banksGroup[activeCountry] &&
              banksGroup[activeCountry].map((bank, i) => (
                <BankTypeListItem key={i} className="slide">
                  {!isSafari && i % 10 === 2 && <BankTypeListVector></BankTypeListVector>}
                  {!isSafari && i % 15 === 8 && <BankTypeListVector2></BankTypeListVector2>}
                  <BankTypeListVectorTop />
                  <BankTypeListItemImage src={bank} height="100" width="250" />
                </BankTypeListItem>
              ))}
            {banksGroup &&
              banksGroup[activeCountry] &&
              banksGroup[activeCountry].map((bank, i) => (
                <BankTypeListItem key={i} className="slide">
                  {!isSafari && i % 10 === 10 && <BankTypeListVector></BankTypeListVector>}
                  {!isSafari && i % 15 === 13 && <BankTypeListVector2></BankTypeListVector2>}
                  <BankTypeListVectorTop />
                  <BankTypeListItemImage src={bank} height="100" width="250" />
                </BankTypeListItem>
              ))}
          </BankTypeListContainerTrack>
        </BankTypeListContainer>
      </BankTypeList>
    </BankTypeRoot>
  );
});
