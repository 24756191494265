import React from 'react'
import './StepOptions.scss'
import { useIntl } from 'react-intl'
import { Phone } from '..'
import useAppContext from '../../context/Context'

export const StepOptions = () => {
    const { step } = useAppContext()
    const [indicator, setIndicator] = React.useState(step)
    const [customClass,setCustomClass] = React.useState('')
    const [activeTab, setActiveTab] = React.useState(1)
    const intl = useIntl()

    React.useEffect(()=>{
        setTimeout(()=>{
            setIndicator(step)
        },500)
    },[step])

    const handleClasses = (element) => {
        switch (element){
            case 1: return 'phone_in'
            case 2: return 'phone_out'
            case 3: return 'phone_out'
            case 4: return 'phone_in'
            case 5: return 'phone_in'
        }
    }

    React.useEffect(()=>{
        if( 
            ( step === 1 && indicator === 2 ) ||
            ( step === 1 && indicator === 3 ) ||
            ( step === 4 && indicator === 2 ) ||
            ( step === 4 && indicator === 3 ) ||
            ( step === 5 && indicator === 2 ) ||
            ( step === 5 && indicator === 3 ) 
        ){
            setCustomClass('animate_phone_out')
        }

        if(
            ( step === 2 && indicator === 1 ) ||
            ( step === 2 && indicator === 4 ) ||
            ( step === 2 && indicator === 5 ) ||
            ( step === 3 && indicator === 1 ) ||
            ( step === 3 && indicator === 4 ) ||
            ( step === 3 && indicator === 5 )
        ){
            setCustomClass('animate_phone_in')
        }
        setTimeout(() =>{
            setCustomClass('')
        },1000)
    },[step])

    const handleConsole = () => {
        if( step < 3 ){
            return <>
                <div>
                        <span>1</span>
                        <p>merchant_code:     cl_sandbox_claro</p>
                    </div>
                    <div>
                        <span>2</span>
                        <p>merchant_api_token: <br/> 8LXT1JaBmmY4bfil7GAjyN6DzmnaIT6CZg8kWXcQUDVj<br/>iwk40PYgh9ZJcFRbh1Vt</p>
                    </div>
                    <div>
                        <span>3</span>
                        <p>URL API:     https://api-sandbox.etpayment.com/</p>
                    </div>
                    <div>
                        <span>4</span>
                        <p>URL WEB:     https://pmt-sandbox.etpayment.com/</p>
                    </div>
            </>
        }else{
           return handleContent()
        }
    }

    const handleContent = () => {
        switch(activeTab){
            case 1: return <>
                    <div>
                        <span>1</span>
                        <p>import requests</p>
                    </div>
                    <div>
                        <span>2</span>
                        <p>{'payload = {'}</p>
                    </div>
                    <div>
                        <span>3</span>
                        <p>          {'"merchant_code":'}     {'"cl_sandbox_demo_wc",'}</p>
                    </div>
                    <div>
                        <span>4</span>
                        <p>          {'"merchant_api_token":'}<br/>{'"v9Z8R2aWAiWgru75MlMXOVw09Pg3oEwjCGsfXElok'}<br/>{'kBpp7QWvzadansVdmhw5P37",'}</p>
                    </div>
                    <div>
                        <span>5</span>
                        <p>          {'"merchant_order_id":'}     {'"OC-123",'}</p>
                    </div>
                    <div>
                        <span>6</span>
                        <p>          {'"order_amount":'}    {'1,'}</p>
                    </div>
                    <div>
                        <span>7</span>
                        <p>          {'"customer_email":'}    {'"",'}</p>
                    </div>
                    <div>
                        <span>8</span>
                        <p>          {'"metadata":'}    {'['} <br/>
                                        {"{"} <br/>
                                                 {'"name":     "Title",'} <br/>
                                                 {'"value":     "subtitle",'} <br/>
                                                 {'"show":     false,'} <br/>
                                        {"}"}
                        </p>
                    </div>
                    <div>
                        <span>9</span>
                        <p>
                            res = requests.post(url=<br/> 'https://api-sandbox.etpayment.com/session<br/> /initialize',
                            json = payload).json()                        
                        </p>
                    </div>
                    <div>
                        <span>10</span>
                        <p>print('payment_token: '+res['token'])</p>
                    </div>
                    <div>
                        <span>11</span>
                        <p>print('signature_token: '+res['signature_token'])</p>
                    </div>
                    <div>
                        <span>12</span>
                        <p>print('terms_url: '+res['terms_url'])</p>
                    </div>
                </>
            case 2: return <>
                        <div>
                            <span>1</span>
                            <p>{"<script>"}</p>
                        </div>
                        <div>
                            <span>2</span>
                            <p>{'<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>'}</p>
                        </div>
                        <div>
                            <span>3</span>
                            <p>{'const data = {'}</p>
                        </div>
                        <div>
                            <span>4</span>
                            <p>          {'"merchant_code":'}     {'"cl_sandbox_demo_wc",'}</p>
                        </div>
                        <div>
                            <span>5</span>
                            <p>          {'"merchant_api_token":'}<br/>{'"v9Z8R2aWAiWgru75MlMXOVw09Pg3oEwjCGsfXElok'}<br/>{'kBpp7QWvzadansVdmhw5P37",'}</p>
                        </div>
                        <div>
                            <span>6</span>
                            <p>          {'"merchant_order_id":'}     {'"OC-123",'}</p>
                        </div>
                        <div>
                            <span>7</span>
                            <p>          {'"order_amount":'}    {'1,'}</p>
                        </div>
                        <div>
                            <span>8</span>
                            <p>          {'"customer_email":'}    {'"",'}</p>
                        </div>
                        <div>
                            <span>9</span>
                            <p>          {'"metadata":'}    {'['} <br/>
                                            {"{"} <br/>
                                                    {'"name":     "Title",'} <br/>
                                                    {'"value":     "subtitle",'} <br/>
                                                    {'"show":     false,'} <br/>
                                            {"}"}
                            </p>
                        </div>
                        <div>
                            <span>10</span>
                            <p>
                                axios.post('https://api-sandbox.etpayment.com/session/initialize', data) <br/>
                                {'.then((res) => {'} <br/>
                                    {'console.log(`Status: ${res.status}`);'} <br/>
                                    console.log('Body: ', res.data); <br/>
                                {'}).catch((err) => {'} <br/>
                                    console.error(err); <br/>
                                {'});'}
                            </p>
                        </div>
                        <div>
                            <span>11</span>
                            <p>{'</script>'}</p>
                        </div>
                </>
                case 3: return <>
                    <div>
                        <span>1</span>
                        <p>curl --request POST \</p>
                    </div>
                    <div>
                        <span>2</span>
                        <p>--header "Content-Type: application/json" \</p>
                    </div>
                    <div>
                        <span>3</span>
                        <p>--data</p>
                    </div>
                    <div>
                        <span>5</span>
                        <p>
                            {'"{\"merchant_code\":\"cl_sandbox_demo_wc\",'}<br/>
                            \"merchant_api_token\":\"v9Z8R2aWAiWgru7<br/>
                            5MlMXOVw09Pg3oEwjCGsfXElokkBpp7QWvz <br/>
                            adansVdmhw5P37\",\"merchant_order_id\":<br/>
                            \"OC-123\",\"order_amount\":1,<br/>
                            \"customer_email\":\"\",\"metadata\":<br/>
                            {'[{\"name\":\"Title\",\"value\":\"subtitle\",\"show\":false}]}"'} \
                        </p>
                    </div>
                    <div>
                        <span>6</span>
                        <p>https://api-sandbox.etpayment.com/<br/>session/initialize</p>
                    </div>
                </>          
        }
    }

    return(
        <div className="container_special_section">
            <div className="container-console">
                <div className="header" style={{ filter: indicator === 1 || indicator === 4 || indicator === 5 ? 'blur(2px)' : ''}}>
                    <div onClick={() =>setActiveTab(1)} style={{
                        backgroundColor: activeTab === 1 ? '#262255' : '',
                    }}>
                        <span>{
                            step === 1 || step === 2
                            ? 'token_ETpay.txt'
                            : 'Pyton'
                        }</span>
                    </div>
                    {
                        step > 2 &&
                        <>
                            <div onClick={() =>setActiveTab(2)} style={{
                                backgroundColor: activeTab === 2 ? '#262255' : '',
                            }}>
                                <span>NodeJS</span>
                            </div>
                            <div onClick={() =>setActiveTab(3)} style={{
                                backgroundColor: activeTab === 3 ? '#262255' : '',
                            }}>
                                <span>cURL</span>
                            </div>
                        </>
                    }                    
                </div>
                <div className="body" style={{ filter: indicator === 1 || indicator === 4 || indicator === 5 ? 'blur(2px)' : ''}}>
                    { handleConsole() }
                </div>
            </div>
            <div className={`phone ${handleClasses(indicator)} ${customClass}`}>
                <Phone indicator={indicator}/>
            </div>
        </div>
    )
}