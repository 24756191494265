import { createRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import isElementTotallyVisible from '../../services/detectElement';
import './Bubble.scss';

export const Bubble = ({ children, customClass, ...otherProps }) => {
  const location = useLocation();
  const bubbleRef = createRef(null);
  const [start, setStart] = useState(
    location.pathname === '/developers' ? true : false
  );

  useEffect(() => {
    window.addEventListener('scroll', () =>
      setStart(isElementTotallyVisible(bubbleRef))
    );

    return () => {
      window.removeEventListener('scroll', () =>
        setStart(isElementTotallyVisible(bubbleRef))
      );
    };
  });

  return (
    <div
      className={`bubble ${customClass} ${start && 'active'}`}
      {...otherProps}
      ref={bubbleRef}
    >
      {children}
      <img src="./dialog-tip.svg" alt="" className="tip" />
    </div>
  );
};
