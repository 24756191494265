import { React } from "react";
import styled, { css } from "styled-components";

const BannerTextContainer = styled.div`
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  align-items: flex-start;
  justify-content: center;
`;

const BannerTextTitle = styled.h1`
  margin-block-start: 0;
  margin-block-end: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-40);
  line-height: 116%;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);

  @media (max-width: 768px) {
    font-size: var(--etpay-font-size-20);
    line-height: 116%;
  }
`;

const BannerTextSubtitle = styled.h2`
  margin-block-start: 0;
  margin-block-end: 0;
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-15);
  line-height: 32px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-vanth);

  @media (max-width: 768px) {
    font-size: var(--etpay-font-size-11);
    line-height: 24px;
  }
`;

export const BannerText = ({ title, subtitle }) => (
  <BannerTextContainer>
    <BannerTextTitle
      dangerouslySetInnerHTML={{ __html: title }}
    ></BannerTextTitle>
    <BannerTextSubtitle
      dangerouslySetInnerHTML={{ __html: subtitle }}
    ></BannerTextSubtitle>
  </BannerTextContainer>
);
