import React from 'react';

import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  font-family: 'Oatmeal Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--etpay-font-size-10);
  line-height: 20px;
  min-width: max-content;

  height: 52px;
  display: var(--etpay-display-flex);
  align-items: center;
  // gap: 8px;
  padding: 0 24px;
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  ${({ color }) => {
    return (
      color &&
      css`
        color: ${color};
      `
    );
  }}
  ${({ bgColor }) => {
    return (
      bgColor &&
      css`
        background-color: ${bgColor};
      `
    );
  }}
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--etpay-color-secondary);
    ${({ bgHoverColor }) => {
      return (
        bgHoverColor &&
        css`
          background-color: ${bgHoverColor};
        `
      );
    }}
    color: white;
    ${({ colorHover }) => {
      return (
        colorHover &&
        css`
          color: ${colorHover} !important;
        `
      );
    }}
    .fillable {
      path {
        ${({ colorHover }) => {
          return (
            colorHover &&
            css`
              fill: ${colorHover};
            `
          );
        }}
      }
    }
  }

  &:hover svg path {
    stroke: var(--etpay-color-pure);
  }

  & p {
    margin: 0;
    white-space: nowrap;
  }
`;

export const Button = ({
  children,
  onHandleClick,
  color,
  colorHover = '#ffffff',
  bgColor,
  bgHoverColor,
  ...otherProps
}) => {
  return (
    <StyledButton
      onClick={onHandleClick}
      color={color}
      colorHover={colorHover}
      bgColor={bgColor}
      bgHoverColor={bgHoverColor}
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
};
