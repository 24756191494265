import { useState } from 'react';
import Lottie from 'lottie-react';

export const AnimateIcon = ({ size = "36px", json }) => {
  let [isHover, setIsHover] = useState(false);
  const styles = {
      width: size,
      height: size
  }
  return (
    <Lottie
      animationData={json}
      loop={isHover}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={styles}
    />
  );
};