import { useIntl } from 'react-intl';
import { Link } from "react-router-dom";
import { Column, Button, Icon, AnimateIcon } from '../../components';
import { useMatch } from "react-router-dom";
import './Slider.scss';

export const Slider = ({
  children,
  icon,
  title,
  description,
  serviceName,
  ...otherProps
}) => {
  const match = useMatch("uses-cases")
  const intl = useIntl()
  return (
    <div className="slider" {...otherProps}>
      <Column customClass="top">
        <div className="left">
          {icon && (
            <div className="service">
              <div className="icon">
                <AnimateIcon json={icon} />
              </div>
              <div className="service-name">{serviceName}</div>
            </div>
          )}
          <h2 className={`title ${!icon ? 'no-icon' : ''}`}>{title}</h2>
        </div>
        <div className="right">
          <h3 className="description">{description}</h3>
          <div>

            <a href={
              !match
                ? 'tel:'+intl.formatMessage({ id: "number-tel" })
                : serviceName === 'Payment Links'
                  ? '/payment-links'
                  : '/quick-transfer'
            }><Button bgColor="#EFEFEF">
                {serviceName === undefined ? 'Llámanos al: '+intl.formatMessage({ id: "number-tel" })+'' : serviceName === 'Payment Links' ? 'Saber más' : 'Saber más'}
                {serviceName===undefined?<Icon name="icon-phone"></Icon>:<Icon name="Icon_After"></Icon>}
              </Button></a>
          </div>
        </div>
      </Column>
      <Column customClass="bottom">{children}</Column>
    </div>
  );
};
