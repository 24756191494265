import { useIntl } from "react-intl";
import { BannerText } from "../../components/BannerText/BannerText";
import { PositionedImage } from "../../components/PositionedImage/PositionedImage";
import { Index, IndexSection } from "../../components/Index/Index";
import "./Privacy.scss";
import { Layout } from "../../components";
import ReactGA from "react-ga4";


export const Privacy = () => {
  ReactGA.send({ hitType: "pageview", page: "/privacy-policy" });

  const intl = useIntl();
  const PRIVACY_COUNT = parseInt(
    intl.formatMessage({ id: "privacyPage.quantity" })
  );
  return (
    <Layout>
      <div className="privacy-page">
        <PositionedImage
          image={require("../../assets/images/img_004.png")}
          width="722px"
          top="100px"
          right="0"
        />

        <PositionedImage
          image={require("../../assets/images/img_003.png")}
          width="722px"
          top="50%"
          lfet="0"
        />

        <PositionedImage
          image={require("../../assets/images/img_002.png")}
          width="722px"
          top="85%"
          right="0"
        />

        <div className="layout-container flex-grid ">
          <div className="banner-section flex-grid__cols-4 flex-grid__cols__offset-4 flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none">
            <BannerText
              title={intl.formatMessage({ id: "privacyPage.header.title" })}
              subtitle={intl.formatMessage({ id: "privacyPage.header.subtitle" })}
            />
          </div>

          <div className="flex-grid__cols-12">
            <Index offset={10}>
              {[...Array(PRIVACY_COUNT)].map((el, index) => (
                <IndexSection
                  title={`${index + 1}. ${intl.formatMessage({
                    id: `privacyPage.section${index + 1}.title`,
                  })}`}
                  sectionKey={`section_${index + 1}`}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: `privacyPage.section${index + 1}.content`,
                      }),
                    }}
                  ></div>
                </IndexSection>
              ))}
            </Index>
          </div>
        </div>
      </div>
    </Layout>
  );
};
