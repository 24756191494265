import React from 'react';
import './Column.scss';

export const Column = React.forwardRef(
  ({ children, customClass, ...otherProps }, ref) => {
    return (
      <div
        ref={ref}
        className={`column-container ${customClass}`}
        {...otherProps}
      >
        <div className="column">{children}</div>
      </div>
    );
  }
);
