import { forwardRef } from "react";
import styled from "styled-components";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { useIntl } from "react-intl";

const PeopleOfficeInformationRoot = styled.div``;

const PeopleOfficeInformationRootText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-23);
  line-height: 124%;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);
  margin-bottom: 29px;

  @media screen and (max-width: 768px) {
    font-weight: 500;
    font-size: var(--etpay-font-size-13)-1;
    line-height: 110%;
    margin-bottom: 46px;
  }
`;

const PeopleOfficeInformationRootActions = styled.div``;

const PeopleOfficeInformationRootSponsors = styled.div`
  margin-top: 50px;
`;

const PeopleOfficeInformationRootSponsorsList = styled.div`
  margin-top: 13px;

  @media screen and (max-width: 768px) {
    margin-top: 17px;
  }
`;

const PeopleOfficeInformationRootSponsorsHeader = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: var(--etpay-font-size-12);
  line-height: 28px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-vanth);

  @media screen and (max-width: 768px) {
    font-size: var(--etpay-font-size-11);
    line-height: 24px;
    letter-spacing: 0.003em;
  }
`;

const PeopleOfficeInformationRootSponsorsLogo = styled.img`
  margin-right: 19px;

  @media screen and (max-width: 768px) {
    width: 133px;
    height: 42px;
  }
`;

export const PeopleOfficeInformation = (props) => {
  const { text, actionText, sponsorHeaderText, sponsorLogos } = props;

  return (
    <PeopleOfficeInformationRoot>
      {/* <PeopleOfficeInformationRootText>{text}</PeopleOfficeInformationRootText>
      <PeopleOfficeInformationRootActions>
        <a href={useIntl().formatMessage({ id: 'manifest'})}>
        <Button bgColor="#EFEFEF">
          {actionText} <Icon name="Icon_After" hoverColor="#ffffff" />
        </Button>
        </a>
      </PeopleOfficeInformationRootActions> */}

      <PeopleOfficeInformationRootSponsors>
        <PeopleOfficeInformationRootSponsorsHeader>
          {sponsorHeaderText}
        </PeopleOfficeInformationRootSponsorsHeader>
        <PeopleOfficeInformationRootSponsorsList>
          {sponsorLogos.map((sponsor, index) => (
            <PeopleOfficeInformationRootSponsorsLogo
              key={sponsor}
              src={sponsor}
            />
          ))}
        </PeopleOfficeInformationRootSponsorsList>
      </PeopleOfficeInformationRootSponsors>
    </PeopleOfficeInformationRoot>
  );
};
