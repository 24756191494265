import { useIntl } from 'react-intl';

import useAppContext from '../../context/Context';
import { Button, Icon, Column } from '../../components';
import './Tarifas.scss';

export const Tarifas = ({ color, type, ...otherProps }) => {
  const { getImages, activeCountry } = useAppContext();
  return (
    <div className="tarifas">
      <div className="back">
        <img
          className="back-icon hide-xs"
          src={getImages['tarifas_background']}
          alt=""
        />
      </div>
      <Column>
        <h4 className="top-title" style={{ color: color }}>
          {useIntl().formatMessage({ id: 'tarifas.topTitle' })}
        </h4>
        <h2 className="title">
          {useIntl().formatMessage({ id: 'tarifas.title' })}
        </h2>
        <h3 className="sub-title">
          {useIntl().formatMessage({ id: 'tarifas.subTitle' })}
        </h3>
      </Column>
      <Column {...otherProps} customClass="content">
        {/* 1 */}
        <div className="title hide-xs">
          {useIntl().formatMessage({
            id: `prices.${type}.eCommerce.title`
          })}
        </div>
        <div className="text">
          <div className="title show-xs">
            {useIntl().formatMessage({
              id: `prices.${type}.eCommerce.title`
            })}
          </div>
          <div className="description">
            {useIntl().formatMessage({
              id: `prices.${type}.eCommerce.description`,
            })}
          </div>
        </div>
        <div className="value">
          <div className="top">
            {useIntl().formatMessage({ id: `prices.${type}.eCommerce.value` })}
          </div>
          <div className="bottom">
            {useIntl().formatMessage({ id: `prices.${type}.eCommerce.metric.1` })}
          </div>
          <div className="bottom">
            {useIntl().formatMessage({ id: `prices.${type}.eCommerce.metric.2` })}
          </div>
        </div>

        {/* 2 */}
        {activeCountry === 'cl' ? <><div className="title hide-xs">
          Servicios básicos
        </div>
          <div className="text">
            <div className="title show-xs">
              Servicios básicos
            </div>
            <div className="description">
              Si tu empresa se encarga de la gestión de los pagos de servicios básicos como luz, gas y agua tenemos un plan.
            </div>
          </div>
          <div className="value">
            <div className="top">
              0,0045 UF
            </div>
            <div className="bottom">
              + IVA
            </div>
            <div className="bottom">
              / trx
            </div>
          </div></> : ''}

        {/* 1 */}
        <div className="title hide-xs">
          {useIntl().formatMessage({
            id: `prices.${type}.volume.title`,
          })}
        </div>
        <div className="text">
          <div className="title show-xs">
            {useIntl().formatMessage({
              id: `prices.${type}.volume.title`,
            })}
          </div>
          <div className="description">
            {useIntl().formatMessage({
              id: `prices.${type}.volume.description`,
            })}
          </div>
        </div>
        <div className="value end">
          <a href={useIntl().formatMessage({ id: "banner.home.button2.url" })}>
            {useIntl().formatMessage({ id: 'prices.link' })}
            <Icon name="Icon_After" className="hide-xs"></Icon>
          </a>
        </div>
      </Column>
    </div>
  );
};
