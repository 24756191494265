import { useState, useEffect } from "react";

/**
 * Function to get country from location
 */
export const fetchCountryApi = async () => {
  const api = `https://pro.ip-api.com/json?key=${process.env.REACT_APP_IP_API}`;

  return await fetch(api)
    .then((res) => {
      // console.log('Llamada!!!')
      if (!res.ok) {
        return {};
      }
      return res.json();
    })
    .then((res) => {
      return res;
    });
};

/**
 * Hook to get user
 */
export const useCurrentLocationInfo = () => {
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    if (data || data === false) return;
    const fetchAPI = async () => {
      setIsLoading(true);
      await fetchCountryApi()
        .then((res) => {
          if (res && !isCancelled) setData(res);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    };
    fetchAPI();
    return () => {
      isCancelled = true;
    };
  }, [data]);

  return { data, error, isLoading };
};
