import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import './App.scss';
import ReactGA from "react-ga4";


import { Header } from "./components";
import {
  Conciliation,
  Cookies,
  Developers,
  Faqs,
  Home,
  NotFound,
  PaymentLinks,
  People,
  PressBand,
  Prices,
  Privacy,
  QuickTransfer,
  Returns,
  Terms,
  UseCases,
} from "./pages/";
import useAppContext from "./context/Context";
import es_CL from "./lang/es_CL.json";
import es_MX from "./lang/es_MX.json";
import { IntlProvider } from "react-intl";

const messages = {
  cl: es_CL,
  mx: es_MX,
};

function App() {
  ReactGA.initialize("G-FVEWCH1KNE");

  const { activeCountry } = useAppContext();
  const appRef = React.createRef(null);
  const [fixed, setFixed] = React.useState(false);
  const location = useLocation();
  useEffect(() => {
    setTimeout(function () {
      window.scrollX = 0;
      window.scrollTo(0, 0);
    }, 0);
  }, [location.key]);

  const handle = () => {
    if (appRef.current) {
      let box = appRef.current.getBoundingClientRect();
      if (box.top * -1 > 110) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handle);

    return () => {
      window.removeEventListener("scroll", handle);
    };
  });

  return (
    <IntlProvider locale={"en"} messages={messages[activeCountry]}>
      <div className="App" ref={appRef}>
        <Header fixed={fixed} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="prices" element={<Prices />} />
          <Route path="developers" element={<Developers />} />
          <Route path="quick-transfer" element={<QuickTransfer />} />
          <Route path="payment-links" element={<PaymentLinks />} />
          <Route path="conciliation" element={<Conciliation />} />
          <Route path="returns" element={<Returns />} />
          <Route path="terms-conditions" element={<Terms />} />
          <Route path="privacy-policy" element={<Privacy />} />
          {/* <Route path="cookies" element={<Cookies />} /> */}
          <Route path="people-office" element={<People />} />
          <Route path="press-brand" element={<PressBand />} />
          <Route path="uses-cases" element={<UseCases />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </IntlProvider>
  );
}

export default App;
