/**
 * Positioned component
 * @property {*} top
 * @property {*} bottom
 * @property {*} left
 * @property {*} right
 * @property {fixed | absolute} variant absolute default value
 * @example <Positioned top="0px" left="0px" />
 */
import { forwardRef, React } from "react";
import styled from "styled-components";

const PositionedRoot = styled.div`
  position: ${(props) => (props.variant ? props.variant : "absolute")};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  z-index: -1;
`;

export const Positioned = forwardRef(function Positioned(props) {
  const { children, ...others } = props;
  return <PositionedRoot {...others}>{children}</PositionedRoot>;
});
