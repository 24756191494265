import { useIntl } from "react-intl";
import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  Banner,
  Content,
  Button,
  Icon,
  SpecialSection,
  EasyIntegration,
  ReadyToStart,
  Options,
  StepOptions,
  Layout,
  PosibleWidthEtpay,
  Tarifas,
  WhatAppClient,
  Convertion,
  ConvertionBelow,
  List,
  ItemList,
  AnimateIcon,
  Gif,
} from "../../components";
import "../../components/Banner/Banner.scss";
import useAppContext from "../../context/Context";
import { quickTransfer } from "../../services/animationIcons";
import "./QuickTransfer.scss";
import cl from "../../banks/quickTransfer_CL.json";
import mx from "../../banks/quickTransfer_MX.json";

gsap.registerPlugin(ScrollTrigger);

export const QuickTransfer = () => {
  ReactGA.send({ hitType: "pageview", page: "/quick-transfer" });

  const { setStep, getImages, activeCountry } = useAppContext();
  const intl = useIntl();
  const color = "#FF7576";
  const [bankList, setBankList] = React.useState(null);

  React.useEffect(() => {
    setStep && setStep(1);
  }, []);

  const handleChangeBank = () => {
    activeCountry === 'cl'
      ? setBankList(cl)
      : setBankList(mx);
  }

  React.useEffect(handleChangeBank, [activeCountry])

  React.useEffect(() => {
    const anim = gsap.timeline({
      scrollTrigger: {
        trigger: "#move-start",
        start: "top 100px",
        end: "3100px 80%",
        scrub: true,
      }
    })
      .to("#move-video-quick", { transform: "translateY(1600px)" })

    return () => {
      anim.kill();
      // anim.scrollTrigger.kill();
    }
  }, []);
  return (
    <Layout className="quick-transfer">
      <Banner
        customClass="banner-quick"
        background={getImages["banner_background_quick"]}
        styles={{ width: '80%' }}
      >

        <Content
          content={<div className="banner-top-icon">
            <div>
              <AnimateIcon json={quickTransfer} />
            </div>
            <p>Quick Transfer</p>
          </div>}
          title={intl.formatMessage({ id: "banner.quick.title" })}
          subtitle={intl.formatMessage({ id: "banner.quick.subtitle" })}
          footer={
            <>
              <a
                href={intl.formatMessage({
                  id: "button.testFree.url",
                })}
              >
                <Button bgColor={"#FF7576"} bgHoverColor="#140F33" color="#fff">
                  <Icon name={"Icon_Pre"} className="fillable" fill="#fff" />
                  <span>
                    {intl.formatMessage({ id: "button.testFree.text" })}
                  </span>
                </Button>
              </a>

              <a href={
                'tel:' + intl.formatMessage({ id: "banner.home.button2.url" })
              }>
                <Button bgColor={"#F2F2F2"} bgHoverColor="#140F33">
                  <span>
                    {intl.formatMessage({ id: "banner.home.button2.text" }) + intl.formatMessage({ id: "banner.home.button2.url" })}
                  </span>
                  <Icon name="icon-phone"></Icon>
                </Button>
              </a>
            </>
          }
          customClass="grid-6"
        />
        <div
          className="image-banner"
          style={{
            gridColumn: "7 / 13",
            marginTop: '4.7rem',
            width: '100%'
          }}
        >

          {<video style={{ width: '100%', borderRadius: '8px', border: "1px solid #E6E6E6" }} autoPlay={true} muted={true} playsInline src={getImages["gif-quick-transfer"]}
          ></video>}

        </div>
      </Banner>
      <div>
        <Convertion
          color={color}
          topTitle={intl.formatMessage({ id: "convertion.topTitle" })}
          title={intl.formatMessage({ id: "convertion.title" })}
          description={intl.formatMessage({ id: "convertion.description" })}
          id="move-start"
        >
          {<video id='move-video-quick' className="show-desktop" style={{ width: '100%', borderRadius: '16px', border: "1px solid #E6E6E6" }} autoPlay={true} muted={true} playsInline src={getImages["gif-quick-transfer-back"]}></video>}
          {<video className="show-mobile" style={{ width: '100%', borderRadius: '16px', border: "1px solid #E6E6E6" }} autoPlay={true} muted={true} playsInline src={getImages["gif-quick-transfer-back"]}></video>}
        </Convertion>
        <ConvertionBelow
          title={intl.formatMessage({ id: "convertionBelow.title" })}
          description={intl.formatMessage({
            id: "convertionBelow.description",
          })}
        >
        </ConvertionBelow>
        <WhatAppClient>
          <h3>
            {intl.formatMessage({
              id: "whatapp.quickTransfer.title",
            })}
          </h3>
          <p>
            {intl.formatMessage({
              id: "whatapp.quickTransfer.text",
            })}
          </p>
          <List customClass="list">
            {
              bankList &&
              Object.keys(bankList).map((bank, i) => (
                !bank.includes("unchecked") &&
                <ItemList key={i}>
                  <Icon name={bankList[bank]['icon']} />
                  <p>{bankList[bank]['text']}</p>
                </ItemList>
              ))
            }
          </List>
          <List customClass="leyend">
            {
              bankList &&
              Object.keys(bankList).map((bank, i) => (
                bank.includes("unchecked") &&
                <ItemList>
                  <Icon name={bankList[bank]['icon']} />
                  <p>{bankList[bank]['text']}</p>
                </ItemList>
              ))
            }
          </List>
        </WhatAppClient>
        <SpecialSection>
          <EasyIntegration></EasyIntegration>
          <Options />
          <StepOptions />
        </SpecialSection>
        {/* <PosibleWidthEtpay color={color}></PosibleWidthEtpay> */}
        <Tarifas color={color} type="QuickTransfer"></Tarifas>
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  );
};
