import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {
  Banner,
  Content,
  Button,
  Icon,
  SpecialSection,
  EasyIntegration,
  ReadyToStart,
  Options,
  StepOptions,
  Layout,
  PosibleWidthEtpay,
  Tarifas,
  WhatAppClient,
  Convertion,
  ConvertionBelow,
  List,
  ItemList,
  AnimateIcon,
  Bubble,
  Gif,
} from "../../components";
import useAppContext from "../../context/Context";
import { paymentLink } from "../../services/animationIcons";
import "./PaymentLinks.scss";


gsap.registerPlugin(ScrollTrigger);
export const PaymentLinks = () => {
  ReactGA.send({ hitType: "pageview", page: "/payment-links" });

  const { setStep, getImages } = useAppContext();
  const intl = useIntl();
  const color = "#857BF9";

  React.useEffect(() => {
    setStep && setStep(1);
  }, []);

  React.useEffect(() => {
    const anim = gsap.timeline({
      scrollTrigger: {
        trigger: "#move-start",
        start: "top 150px",
        end: "1600px 40%",
        scrub: true,
      }
    })
      .to("#move-video-pay", { transform: "translateY(800px)" })

    return () => {
      anim.kill();
      // anim.scrollTrigger.kill();
    }
  }, []);

  return (
    <Layout className="payment-link">
      <Banner
        customClass="banner-quick"
        background={getImages["banner_background_payment"]}
      >

        <Content
          content={<div className="banner-top-icon">
            <div>
              <AnimateIcon json={paymentLink} />
            </div>
            <p>Payment Links</p>
          </div>}
          title={intl.formatMessage({ id: "banner.payment.title" })}
          subtitle={intl.formatMessage({ id: "banner.payment.subtitle" })}
          footer={
            <>
              <a
                href={intl.formatMessage({
                  id: "button.testFree.url",
                })}
              >
                <Button bgColor={"#857BF9"} bgHoverColor="#140F33" color="#fff">
                  <Icon name={"Icon_Pre"} className="fillable" fill="#fff" />
                  <span>
                    {intl.formatMessage({ id: "button.testFree.text" })}
                  </span>
                </Button>
              </a>

              <a href={
                'tel:' + intl.formatMessage({ id: "banner.home.button2.url" })
              }>
                <Button bgColor={"#F2F2F2"} bgHoverColor="#140F33">
                  <span>
                    {intl.formatMessage({ id: "banner.home.button2.text" }) + intl.formatMessage({ id: "banner.home.button2.url" })}
                  </span>
                  <Icon name="icon-phone"></Icon>
                </Button>
              </a>
            </>
          }
          customClass="grid-6"
        />
        <div
          className="image-banner"
          style={{
            gridColumn: "7 / 13",
            marginTop: 0,
          }}
        >
          <img src={getImages["banner_image_payment"]} />
        </div>
      </Banner>

      <div>
        <Convertion
          color={color}
          topTitle={intl.formatMessage({ id: "multichannel.topTitle" })}
          title={intl.formatMessage({ id: "multichannel.title" })}
          description={intl.formatMessage({ id: "multichannel.description" })}
        >
          <Bubble>
            <div style={{ width: '100%' }}>
              {intl.formatMessage({ id: "payment.bubble" })}
              <a> https://link.pay</a>
            </div>
          </Bubble>
        </Convertion>
        <ConvertionBelow
          title={intl.formatMessage({ id: "multichannelBelow.title" })}
          description={intl.formatMessage({
            id: "multichannelBelow.description",
          })}
          id='move-start'
        >
          {<video id="move-video-pay" className="show-desktop" style={{ width: '45%', borderRadius: '35px', border: "1px solid #E6E6E6" }} autoPlay={true} muted={true} playsInline src={getImages["gif-payment-links-back"]}></video>}
          {<video className="show-mobile" style={{ width: '75%', borderRadius: '35px', border: "1px solid #E6E6E6", marginTop: '5rem' }} autoPlay={true} muted={true} playsInline src={getImages["gif-payment-links-back"]}></video>}
        </ConvertionBelow>
        <WhatAppClient>
          <h3>
            {intl.formatMessage({
              id: "whatapp.paymentLink.title",
            })}
          </h3>
          <p>
            {intl.formatMessage({
              id: "whatapp.paymentLink.text",
            })}
          </p>
          <List customClass="list">
            <ItemList>
              <Icon name="Check2" />
              <p>
                {intl.formatMessage({
                  id: "whatapp.paymentLink.list.item1",
                })}
              </p>
            </ItemList>
            <ItemList>
              <Icon name="Check2" />
              <p>
                {intl.formatMessage({
                  id: "whatapp.paymentLink.list.item2",
                })}
              </p>
            </ItemList>
            <ItemList>
              <Icon name="Check2" />
              <p>
                {intl.formatMessage({
                  id: "whatapp.paymentLink.list.item3",
                })}
              </p>
            </ItemList>
            <ItemList>
              <Icon name="Check2" />
              <p>
                {intl.formatMessage({
                  id: "whatapp.paymentLink.list.item4",
                })}
              </p>
            </ItemList>
            <ItemList>
              <Icon name="Check2" />
              <p>
                {intl.formatMessage({
                  id: "whatapp.paymentLink.list.item5",
                })}
              </p>
            </ItemList>
          </List>
        </WhatAppClient>
        <SpecialSection>
          <EasyIntegration></EasyIntegration>
          <Options />
          <StepOptions />
        </SpecialSection>
        {/* <PosibleWidthEtpay color={color}></PosibleWidthEtpay> */}
        <Tarifas color={color} type="PaymentLink"></Tarifas>
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  );
};