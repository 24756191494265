import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import React from "react";
import ReactGA from "react-ga4";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


import {
  Icon,
  Banner,
  Button,
  Content,
  ReadyToStart,
  IncludeProduct,
  PosibleWidthEtpay,
  InformationGroup,
  InformationGroupItem,
  Layout,
  Tips,
  Column,
  List,
  ItemList,
} from "../../components";
import "./Conciliation.scss";
import useAppContext from "../../context/Context";
import cl from '../../banks/conciliation_CL.json'
import mx from "../../banks/conciliation_MX.json"

gsap.registerPlugin(ScrollTrigger);

const color = "#262255";

export const Conciliation = () => {
  ReactGA.send({ hitType: "pageview", page: "/conciliation" });
  const intl = useIntl();
  const { getImages } = useAppContext();
  const [bankList, setBankList] = React.useState(null);
  const { setStep, activeCountry } = useAppContext();

  React.useEffect(() => {
    setStep && setStep(1);
  }, []);

  const handleChangeBank = () => {
    activeCountry === 'cl'
      ? setBankList(cl)
      : setBankList(mx);
  }

  React.useEffect(handleChangeBank, [activeCountry])

  React.useEffect(() => {

    const tlData = {
      trigger: ".animation-container",
      start: "top top",
      end: "90% 40%",
      scrub: 1,
      pin: true,
      // markers: true,
    };

    // back mobile
    gsap.timeline({
      scrollTrigger: {
        trigger: ".animation-container-mobile-back",
        start: "top top",
        end: "90% 40%",
        scrub: true,
        pin: true,
      }
    })

    // #animation
    gsap.timeline({
      scrollTrigger: tlData
    })
      .to(".animation", {
        rotate: -30,
      })
      .to(".animation", {
        rotate: 60,
      })
      .to(".animation", {
        rotate: 60,
      })

    // box 1
    gsap.timeline({ scrollTrigger: tlData })
      .to(".box1", {
        transform: "translateX(-100%)",
      })
      .to(".box1", {
        transform: "translateX(90%)",
      })
      .to(".box1", {
        transform: "translate(calc(100% + 20px),calc(100% + 20px))",
      })

    // box 2
    gsap.timeline({ scrollTrigger: tlData })
      .to(".box2", {
        transform: "translateX(0)",
      })
      .to(".box2", {
        transform: "translateX(0)",
      })
      .to(".box2", {
        transform: "translateY(calc(100% + 20px))",
      })

    // box 3
    gsap.timeline({ scrollTrigger: tlData })
      .to(".box3", {
        transform: "translateX(100%)",
      })
      .to(".box3", {
        transform: "translateX(-90%)",
      })
      .to(".box3", {
        transform: "translate(calc(-100% - 20px),calc(100% + 20px))",
      })

    // box 4
    gsap.timeline({ scrollTrigger: tlData })
      .to(".box4", {
        transform: "translate(0)",
      })
      .to(".box4", {
        transform: "translateX(0)",
      })
      .to(".box4", {
        transform: "translateX(calc(100% + 20px))",
      })

    // box 5
    gsap.timeline({ scrollTrigger: tlData })
      .to(".box5", {
        transform: "translateY(100%)",
      })
      .to(".box5", {
        transform: "translateY(-90%)",
      })
      .to(".box5", {
        transform: "translateY(0)",
      })

    // box 6
    gsap.timeline({ scrollTrigger: tlData })
      .to(".box6", {
        transform: "translateX(0)",
      })
      .to(".box6", {
        transform: "translateX(0)",
      })
      .to(".box6", {
        transform: "translateX(calc(-100% - 20px))",
      })

  }, []);

  return (
    <Layout>
      <Banner
        customClass="banner-quick"
        background={getImages["banner_background_conciliation"]}
      >

        <Content
          content={<div className="banner-top-icon">
            <div>
              <Icon name="Conciliacion" />
            </div>
            <p>Conciliación Automática</p>
          </div>}
          title={intl.formatMessage({ id: "banner.conciliation.title" })}
          subtitle={intl.formatMessage({ id: "banner.conciliation.subtitle" })}
          footer={
            <>
              <a
                href={intl.formatMessage({
                  id: "button.testFree.url",
                })}
              >
                <Button bgColor={color} bgHoverColor="#140F33" color="#fff">
                  <Icon name={"Icon_Pre"} fill="#fff" />
                  <span>
                    {intl.formatMessage({ id: "button.testFree.text" })}
                  </span>
                </Button>
              </a>

              <a href={
                'tel:' + intl.formatMessage({ id: "banner.home.button2.url" })
              }>
                <Button bgColor={"#F2F2F2"} bgHoverColor="#140F33">
                  <span>
                    {intl.formatMessage({ id: "banner.home.button2.text" }) + intl.formatMessage({ id: "banner.home.button2.url" })}
                  </span>
                  <Icon name="icon-phone"></Icon>
                </Button>
              </a>
            </>
          }
          customClass="grid-5"
        />
        <div
          className="image-banner"
          style={{
            gridColumn: "7 / 13",
            marginTop: 0,
          }}
        >
          <img src={getImages["banner_image_conciliation"]} alt="" />
        </div>
      </Banner>
      <div className="conciliation-page">
        <Tips
          tip1={intl.formatMessage({ id: "conciliation.section1.t1" })}
          tip2={intl.formatMessage({ id: "conciliation.section1.t2" })}
          tip3={intl.formatMessage({ id: "conciliation.section1.t3" })}
        ></Tips>
        <Column customClass="section2">
          <div className="list">
            <div className="part">
              <h4 className="top-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t1" })}
              </h4>
              <h2 className="title">
                {useIntl().formatMessage({ id: "conciliation.section2.t2" })}
              </h2>
              <h3 className="top-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t3" })}
              </h3>
            </div>

            <div className="part">
              <h4 className="top-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t4" })}
              </h4>
              <div className="sub-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t5" })}
              </div>
            </div>

            <div className="part">
              <h4 className="top-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t6" })}
              </h4>
              <div className="sub-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t7" })}
              </div>
              <List>
                {bankList && Object.keys(bankList).map((bank) => (
                  !bank.includes("unchecked") &&
                  <ItemList key={bankList[bank]["text"]}>
                    <Icon name={bankList[bank]["icon"]} size="24px" />
                    <p>{bankList[bank]["text"]}</p>
                    {bankList[bank]["next"] === 'true' && <label>Proximamente</label>}
                  </ItemList>
                ))}
              </List>

              <List customClass="leyend">
                {
                  bankList &&
                  Object.keys(bankList).map((bank, i) => (
                    bank.includes("unchecked") &&
                    <ItemList>
                      <Icon name={bankList[bank]['icon']} size="24px" />
                      <p>{bankList[bank]['text']}</p>
                    </ItemList>
                  ))
                }
              </List>
            </div>

            <div className="part">
              <h4 className="top-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t10" })}
              </h4>
              <div className="sub-title">
                {useIntl().formatMessage({ id: "conciliation.section2.t11" })}
              </div>
            </div>
          </div>

          <div className="animation-container">
            <div
              className="animation-container-mobile-back"
            ></div>
            <div
              className="animation"
            >
              <div
                className="box-container"
              >
                {/* box1 */}
                <div
                  className="box1 box box-light"
                ></div>
                {/* box2 */}
                <div
                  className="box2 box"
                ></div>
                {/* box3 */}
                <div
                  className="box3 box box-light"
                ></div>
                {/* box4 */}
                <div
                  className="box4 box"
                ></div>
                {/* box5 */}
                <div
                  className="box5 box box-light"
                ></div>
                {/* box6 */}
                <div
                  className="box6 box"
                ></div>
              </div>
            </div>
          </div>
        </Column>

        {activeCountry === 'mx' ? '' : <InformationGroup
          color={color}
          topTitle={intl.formatMessage({
            id: "conciliation.informationGroup.topTitle",
          })}
          title={intl.formatMessage({
            id: "conciliation.informationGroup.title",
          })}
          description={intl.formatMessage({
            id: "conciliation.informationGroup.description",
          })}
        >
          <InformationGroupItem
            icon="Exclude"
            description={intl.formatMessage({
              id: "conciliation.informationGroup.item1.description",
            })}
            color={color}
          ></InformationGroupItem>
          <InformationGroupItem
            icon="ReadWrite"
            description={intl.formatMessage({
              id: "conciliation.informationGroup.item2.description",
            })}
            color={color}
          ></InformationGroupItem>
          <InformationGroupItem
            icon="Bank"
            description={intl.formatMessage({
              id: "conciliation.informationGroup.item3.description",
            })}
            color={color}
          ></InformationGroupItem>
          <InformationGroupItem
            icon="delivery"
            description={intl.formatMessage({
              id: "conciliation.informationGroup.item4.description",
            })}
            color={color}
          ></InformationGroupItem>
        </InformationGroup>}
        {/* <PosibleWidthEtpay color={color}></PosibleWidthEtpay> */}
        <IncludeProduct></IncludeProduct>
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  );
};
