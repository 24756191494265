import React from 'react'
import './Counter.scss'

export const Counter = ({number}) =>{
    const [counter, setCounter] = React.useState(parseInt((number*80)/100))
    const [time,setTime] = React.useState(60)

    const handleCounter = () =>{
        if(number > 3){
            if( counter === (number - 3))
            setTime(650)
        }
        setTimeout(() =>{
            if( counter !== number ){
                setCounter(counter + 1)
            }
        },time)
    }

    React.useEffect(handleCounter,[counter])

    return(
        <div className="counter">{counter}</div>
    )
}