import React from "react";
import "./ItemList.scss";

export const ItemList = ({
  children,
  onHandleClick = () => {},
  ...otherProps
}) => {
  return (
    <div className="container" onClick={onHandleClick} {...otherProps}>
      {children}
    </div>
  );
};
