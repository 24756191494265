import React from 'react'
import './Options.scss'
import {useIntl} from 'react-intl'
import useAppContext from '../../context/Context'
import Timer from '../../services/timer'

const OPTIONS = [
    {
        number: 1,
        title: 'special.option_1.title',
        description: 'special.option_1.description'
    },
    {
        number: 2,
        title: 'special.option_2.title',
        description: 'special.option_2.description'
    },
    {
        number: 3,
        title: 'special.option_3.title',
        description: 'special.option_3.description'
    },
    {
        number: 4,
        title: 'special.option_4.title',
        description: 'special.option_4.description'
    },
    {
        number: 5,
        title: 'special.option_5.title',
        description: 'special.option_5.description'
    }
]

export const Options = () => {
    const intl = useIntl()
    const { step, setStep } = useAppContext()
    const [progress, setProgress] = React.useState(0)
    const [play, setPlay] = React.useState(true)

    const giveTime = time => {
        setPlay(false)

        setTimeout(() =>{
            setPlay(true)
        },time)
    }
    
    const handleOption = (number) => {
        setStep && setStep(number)
        setProgress(0)
        timer.pause()
        giveTime(60000)
    }
    
    const handleCounter = () =>{
        if(progress <= 100){
            setProgress( progress + 1 )
        }
        
        if( progress === 100 ){
            step === 5 ? (setStep && setStep(1)) : (setStep && setStep(step + 1))
            setProgress(0)
            giveTime(500)
        }
    }
    
    const timer = new Timer(handleCounter,167)
    timer.pause()

    React.useEffect(()=>{
        play && timer.resume()
    },[progress, play])

    return(
        <div className="options-container">
            {
                OPTIONS.map(option => (
                    <div 
                        className={`option border ${step === option.number ? 'active' : ''}`} 
                        key={option.number}
                        onClick={()=>handleOption(option.number)}
                    >
                        <div className={`number border ${step === option.number ? 'number__active' : ''}`}>
                            <span>{ option.number }</span>
                            {
                                step === option.number &&
                                <Progress strokeWidth="2" percentage={progress} />
                            }
                        </div>
                        <div className="text">
                            <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: option.title }) }} style={{
                                marginBottom: 10,color:'var(--etpay-color-sirus)'
                            }} />
                        {
                            step === option.number &&
                            <>
                                <div style={{ fontWeight: 400, color:'var(--etpay-color-sirus)'}} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: option.description }) }} />
                            </>
                        }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const Progress = ({ strokeWidth, percentage }) => {
	const radius = (30 - strokeWidth / 2);
    const pathDescription = `
      M 30,30 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

    const diameter = Math.PI * 2 * radius;
    const progressStyle = {
			stroke:"#140F33",
  		strokeLinecap: 'round',
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
    };

    return (
      <svg
        className={'CircularProgressbar'}
        viewBox="0 0 60 60"
				width={53}
				height={53}
      >
        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
					style={{
						stroke:"#857BF9"
					}}
        />
        <path
          className="CircularProgressbar-path"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />
      </svg>
    );
};