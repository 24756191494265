import React from 'react'
import './Tabs.scss'

export const Tabs = ({list, page}) => {
    const [active, setActive] = React.useState(list[0].header)

    const handleActive = active => {
        setActive(active)
    }
    return(
        <>
        <div className={`tabs tabs__${page}`}>
        {
            list.map((item,i) =>(
                <div 
                    key={i}
                    className={`tabs__header ${item.header === active ? `tabs__header__${page}-active` : ''}`}
                    onClick={()=>handleActive(item.header)}
                >{ item.header }</div>
            ))
        }
        </div>
        <div 
            className="tabs__body"
            dangerouslySetInnerHTML={{ __html: list.find(item => item.header === active).body}}
        />
        </>
    )
}