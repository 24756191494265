import { useState, createRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Column, Counter } from '..';
import './SafeMethod.scss';
import isElementTotallyVisible from '../../services/detectElement';

export const SafeMethod = () => {
  const [active, setActive] = useState(false);
  const safeRef = createRef(null);

  const handle = () => {
    setActive(isElementTotallyVisible(safeRef));
  };

  useEffect(() => {
    window.addEventListener('scroll', handle);

    return () => {
      window.removeEventListener('scroll', handle);
    };
  });

  return (
    <Column ref={safeRef} customClass="safe-method">
      <h2 className="title" dangerouslySetInnerHTML={{ __html: useIntl().formatMessage({ id: 'safeMethod.title' }) }} />
      <h3 className="description">
        {useIntl().formatMessage({ id: 'safeMethod.description' })}
      </h3>
      <div className="gap"></div>
      <div className="item">
        <div className="item__title">
          <div>~{active ? <Counter number={60} /> : 0}</div>
          {useIntl().formatMessage({ id: 'safeMethod.item1.title' })}
        </div>
        <div className="item__description">
          {useIntl().formatMessage({ id: 'safeMethod.item1.description' })}
        </div>
      </div>
      <div className="item">
        <div className="item__title">
          <div>+{active ? <Counter number={80} /> : 0}%</div>
          {useIntl().formatMessage({ id: 'safeMethod.item2.title' })}
        </div>
        <div className="item__description">
          {useIntl().formatMessage({ id: 'safeMethod.item2.description' })}
        </div>
      </div>
      <div className="item">
        <div className="item__title">
          <div>+{active ? <Counter number={55} /> : 0}%</div>
          {useIntl().formatMessage({ id: 'safeMethod.item3.title' })}
        </div>
        <div className="item__description">
          {useIntl().formatMessage({ id: 'safeMethod.item3.description' })}
        </div>
      </div>
    </Column>
  );
};
