import { useIntl } from "react-intl";

import { Button, Column, Icon, PosibleWidthEtpay, ReadyToStart, Select, TabBox, Layout } from '../../components';
import "./Prices.scss";
import useAppContext from "../../context/Context";
import { tab } from "@testing-library/user-event/dist/tab";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

export const Prices = () => {
  ReactGA.send({ hitType: "pageview", page: "/prices" });
  const intl = useIntl();
  const { countries, activeCountry } = useAppContext()
  const tab1 =
    <div className="tab-content">
      {/* 1 */}
      <div className="tab-content__title hide-xs">
        E-Commerce y negocios      </div>
      <div className="tab-content__text">
        <div className="tab-content__text--title show-xs">
          E-Commerce y negocios
        </div>
        <div className="tab-content__text--description">
          Si tu negocio es una plataforma ecommerce o un portal web, esta es tu mejor opción.</div>
      </div>
      <div className="tab-content__value">
        <div className="tab-content__value--top">
          {activeCountry === 'mx' ? '$10 MXN' : '1%'}        </div>
        <div className="tab-content__value--bottom">
          + IVA         </div>
        <div className="tab-content__value--bottom">
          / trx         </div>
      </div>

      {/* 2 */}
      {activeCountry === 'mx' ? '' : <><div className="tab-content__title hide-xs">
        Servicios básicos
      </div>
        <div className="tab-content__text">
          <div className="tab-content__text--title show-xs">
            Servicios básicos
          </div>
          <div className="tab-content__text--description">
            Si tu empresa se encarga de la gestión de los pagos de servicios básicos como luz, gas y agua tenemos un plan.</div>
        </div>
        <div className="tab-content__value">
          <div className="tab-content__value--top">
            0,0045 UF        </div>
          <div className="tab-content__value--bottom">
            + IVA        </div>
          <div className="tab-content__value--bottom">
            / trx        </div>
        </div></>}

      {/* 1 */}
      <div className="tab-content__title hide-xs">
        Gran volumen
      </div>
      <div className="tab-content__text">
        <div className="tab-content__text--title show-xs">
          Gran volumen
        </div>
        <div className="tab-content__text--description">
          En caso de tener un servicio con gran volumen de transacciones, contacte con ventas para personalizar su oferta.</div>
      </div>
      <div className="tab-content__value end">
        <div>
        <a href={
                'tel:'+intl.formatMessage({ id: "banner.home.button2.url" })
              }><Button bgColor="#EFEFEF" >Contáctanos
            <Icon name="icon-phone" className='hide-xs'></Icon>
          </Button></a>
        </div>
      </div>
    </div>

  const tab2 =
    <div className="tab-content">
      {/* 1 */}
      <div className="tab-content__title hide-xs">
        {useIntl().formatMessage({ id: 'prices.PaymentLink.eCommerce.title' })}
      </div>
      <div className="tab-content__text">
        <div className="tab-content__text--title show-xs">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.eCommerce.title' })}
        </div>
        <div className="tab-content__text--description">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.eCommerce.description' })}
        </div>
      </div>
      <div className="tab-content__value">
        <div className="tab-content__value--top">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.eCommerce.value' })}
        </div>
        <div className="tab-content__value--bottom">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.eCommerce.metric.1' })}
        </div>
        <div className="tab-content__value--bottom">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.eCommerce.metric.2' })}
        </div>
      </div>

      {/* 2 */}
      <div className="tab-content__title hide-xs">
        {useIntl().formatMessage({ id: 'prices.PaymentLink.services.title' })}
      </div>
      <div className="tab-content__text">
        <div className="tab-content__text--title show-xs">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.services.title' })}
        </div>
        <div className="tab-content__text--description">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.services.description' })}
        </div>
      </div>
      <div className="tab-content__value">
        <div className="tab-content__value--top">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.services.value' })}
        </div>
        <div className="tab-content__value--bottom">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.services.metric.1' })}
        </div>
        <div className="tab-content__value--bottom">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.services.metric.2' })}
        </div>
      </div>

      {/* 1 */}
      <div className="tab-content__title hide-xs">
        {useIntl().formatMessage({ id: 'prices.PaymentLink.volume.title' })}
      </div>
      <div className="tab-content__text">
        <div className="tab-content__text--title show-xs">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.volume.title' })}
        </div>
        <div className="tab-content__text--description">
          {useIntl().formatMessage({ id: 'prices.PaymentLink.volume.description' })}
        </div>
      </div>
      <div className="tab-content__value end">
        <div>
        <a href={
                'tel:'+intl.formatMessage({ id: "banner.home.button2.url" })
              }><Button bgColor="#EFEFEF" >Contáctanos
            <Icon name="icon-phone" className='hide-xs'></Icon>
          </Button></a>
        </div>
      </div>
    </div>

  return (
    <Layout>
      <div className="prices">
        <Column customClass="banner">
          <img className="back-icon" src={require('../../assets/images/banners/ETpay-grad_07.jpg')} alt="" />
          <h1 className="title">{useIntl().formatMessage({ id: 'prices.title' })}</h1>
          <h3 className="description">{useIntl().formatMessage({ id: 'prices.description' })}</h3>
          <div className="select">
            <Select />
          </div>
          {/* tab 1 is repeated because in design dont have the info */}
          <TabBox tabList={activeCountry === 'mx' ? ['QuickTransfer'] : ['QuickTransfer', 'Payment Links']} tabContent={activeCountry === 'mx' ? [tab1] : [tab1, tab2]} className='tab-box'></TabBox>
        </Column>

        <Column customClass='bottom'>
          <h2 className="title">{useIntl().formatMessage({ id: 'prices.bottom.title' })}</h2>
          <h3 className="description">{useIntl().formatMessage({ id: 'prices.bottom.description' })}</h3>
          <div className="span"></div>
          <div className="list">
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item1` })}
            </div>
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item2` })}
            </div>
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item3` })}
            </div>
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item4` })}
            </div>
          </div>
          <div className="list">
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item5` })}
            </div>
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item6` })}
            </div>
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item7` })}
            </div>
            <div className="item">
              <Icon name='Check' size='18px'></Icon>
              {useIntl().formatMessage({ id: `prices.bottom.list.item8` })}
            </div>
          </div>
        </Column>

        {/* <PosibleWidthEtpay color='#72DBAA'></PosibleWidthEtpay> */}
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  );
};
