import { memo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ButtonSlider } from '../ButtonSlider/ButtonSlider';

const SafePaymentRoot = styled.div`
  width: 100%;
  height: 419px;
  padding: 32px;
  background-color: var(--etpay-color-orion);
  background-image: url('${require('../../assets/images/img_007.png')}');
  background-position: 100% 100%;
  background-repeat: no-repeat;

  border-radius: 24px;

  @media (max-width: 768px) {
    height: 611px;
    padding: 33px 24px;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    align-items: center;
    justify-content: space-between;

    background-image: url('${require('../../assets/images/img_008.png')}');
    background-position: right;
    background-size: cover;
  }
`;

const SafePaymentHead = styled.div`
  margin-bottom: 29px;
`;

const SafePaymentHeadTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-11);
  line-height: 32px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-vanth);

  margin-bottom: 16px;
`;

const SafePaymentHeadSubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: var(--etpay-font-size-11);
  line-height: 28px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-sirus);

  width: min(100%, 399px);
`;

const SafePaymentButtons = styled.div`
  width: 100%;
`;

const SafePaymentButtonsItem = styled.div`
  margin-bottom: 12px;
  margin-left: ${(props) => `${props.margin}px`};

  @media (max-width: 768px) {
    margin-left: ${(props) => `${props.marginMobile}px`};
  }
`;

export const SafePayment = memo(() => {
  const intl = useIntl();
  return (
    <SafePaymentRoot>
      <SafePaymentHead>
        <SafePaymentHeadTitle>
          {intl.formatMessage({ id: 'safePayment.title' })}
        </SafePaymentHeadTitle>
        <SafePaymentHeadSubTitle>
          {intl.formatMessage({ id: 'safePayment.subTitle' })}
        </SafePaymentHeadSubTitle>
      </SafePaymentHead>
      <SafePaymentButtons>
        <SafePaymentButtonsItem marginMobile="30">
          <ButtonSlider
            delay={4508}
            items={[
              {
                icon: 'Shield',
                text: intl.formatMessage({
                  id: 'safePayment.button.1',
                }),
                variant: 'blue',
              },
              {
                child: (
                  <>
                    <span>
                      {intl.formatMessage({
                        id: 'safePayment.button.2.2',
                      })}
                    </span>
                    <span>$10.000</span>
                  </>
                ),
                variant: 'white',
              },
            ]}
          />
        </SafePaymentButtonsItem>
        <SafePaymentButtonsItem margin="40" marginMobile="15">
          <ButtonSlider
            delay={3000}
            items={[
              {
                icon: 'Layers',
                text: intl.formatMessage({
                  id: 'safePayment.button.2.1',
                }),
                variant: 'blue',
              },
              {
                child: (
                  <>
                    <span>
                      {intl.formatMessage({
                        id: 'safePayment.button.2.2',
                      })}
                    </span>
                    <span>$10.000</span>
                  </>
                ),
                variant: 'white',
              },
            ]}
          />
        </SafePaymentButtonsItem>
        <SafePaymentButtonsItem margin="80" marginMobile="0">
          <ButtonSlider
            delay={6207}
            items={[
              {
                icon: 'Coffee',
                text: intl.formatMessage({
                  id: 'safePayment.button.3',
                }),
                variant: 'blue',
              },
              {
                child: (
                  <>
                    <span>
                      {intl.formatMessage({
                        id: 'safePayment.button.2.2',
                      })}
                    </span>
                    <span>$10.000</span>
                  </>
                ),
                variant: 'white',
              },
            ]}
          />
        </SafePaymentButtonsItem>
      </SafePaymentButtons>
    </SafePaymentRoot>
  );
});
