import { createRef, useEffect, useState } from 'react';
import isElementTotallyVisible from '../../services/detectElement';
import './Console.scss';
import { useLocation } from 'react-router-dom';

const LINE_1 =
  "          constant etpay = require('QT')('sk_test_13513');     ";
const LINE_2 = '     ';
const LINE_3 = '     await etpay.paymentIntents.creat({     ';
const LINE_4 = '     amount: 2000,  ';
const LINE_5 = "     currency: 'CTP',  ";
const LINE_6 = '     });';
const LINE_SHELL_1 = '          node server.js && etpay listen           ';

const DICTIONARY = [
  {
    text: 'constant',
    replaceText: '<span class="color1">constant</span>',
  },
  {
    text: 'await',
    replaceText: '<span class="color1">await</span>',
  },
  {
    text: 'require',
    replaceText: '<span class="color2">require</span>',
  },
  {
    text: 'creat',
    replaceText: '<span class="color2">create</span>',
  },
  {
    text: "'QT'",
    replaceText: "<span class='color3'>'QT'</span>",
  },
  {
    text: "'sk_test_13513'",
    replaceText: "<span class='color3'>'sk_test_13513'</span>",
  },
  {
    text: "'CTP'",
    replaceText: "<span class='color3'>'CTP'</span>",
  },
  {
    text: '(',
    replaceText: '<span class="color4">(</span>',
  },
  {
    text: ')',
    replaceText: '<span class="color4">)</span>',
  },
  {
    text: '{',
    replaceText: '<span class="color4">{</span>',
  },
  {
    text: '}',
    replaceText: '<span class="color4">}</span>',
  },
  {
    text: ';',
    replaceText: '<span class="color4">;</span>',
  },
  {
    text: '2000',
    replaceText: '<span class="color5">2000</span>',
  },
];

const LineNumber = ({ number }) => {
  return <div className="line-number">{number}</div>;
};

export const Console = ({ customClasses }) => {
  const location = useLocation();
  const consoleRef = createRef(null);
  const [start, setStart] = useState(
    location.pathname === '/developers' ? true : false
  );
  let [line, setLine] = useState(1);
  let [lineShell, setLineShell] = useState(1);

  let [line1Leng, setLine1Leng] = useState(0);
  let [line2Leng, setLine2Leng] = useState(0);
  let [line3Leng, setLine3Leng] = useState(0);
  let [line4Leng, setLine4Leng] = useState(0);
  let [line5Leng, setLine5Leng] = useState(0);
  let [line6Leng, setLine6Leng] = useState(0);
  let [line1, setLine1] = useState('');
  let [line2, setLine2] = useState('');
  let [line3, setLine3] = useState('');
  let [line4, setLine4] = useState('');
  let [line5, setLine5] = useState('');
  let [line6, setLine6] = useState('');

  let [lineShell1Leng, setLineShell1Leng] = useState(0);
  let [lineShell1, setLineShell1] = useState('');
  let [lineShell2, setLineShell2] = useState(false);
  let [lineShell3, setLineShell3] = useState(false);

  const getLeng = () => {
    let leng = null;
    let L = null;
    switch (line) {
      case 2:
        leng = line2Leng;
        L = LINE_2;
        break;
      case 3:
        leng = line3Leng;
        L = LINE_3;
        break;
      case 4:
        leng = line4Leng;
        L = LINE_4;
        break;
      case 5:
        leng = line5Leng;
        L = LINE_5;
        break;
      case 6:
        leng = line6Leng;
        L = LINE_6;
        break;
      default:
        leng = line1Leng;
        L = LINE_1;
        break;
    }
    return L.substring(0, leng).trim().length;
  };

  const isEnd = () => {
    return line >= 6 && getLeng() >= 3;
  };

  const checkColor = () => {
    let lineTemp = null;
    let setLineTemp = null;

    switch (line) {
      case 2:
        lineTemp = line2;
        setLineTemp = setLine2;
        break;

      case 3:
        lineTemp = line3;
        setLineTemp = setLine3;
        break;

      case 4:
        lineTemp = line4;
        setLineTemp = setLine4;
        break;

      case 5:
        lineTemp = line5;
        setLineTemp = setLine5;
        break;

      case 6:
        lineTemp = line6;
        setLineTemp = setLine6;
        break;

      default:
        lineTemp = line1;
        setLineTemp = setLine1;
        break;
    }

    for (let i = 0; i < DICTIONARY.length; i++) {
      const element = DICTIONARY[i];
      if (
        lineTemp.split(element.replaceText).length !==
        lineTemp.split(element.text).length
      )
        setLineTemp(lineTemp.split(element.text).join(element.replaceText));
    }
  };

  const checkAnimationEditor = () => {
    let LINE = null;
    let lineTemp = null;
    let lineLengTemp = null;
    let setLineTemp = null;
    let setLineLengTemp = null;

    switch (line) {
      case 2:
        LINE = LINE_2;
        lineTemp = line2;
        lineLengTemp = line2Leng;
        setLineTemp = setLine2;
        setLineLengTemp = setLine2Leng;
        break;
      case 3:
        LINE = LINE_3;
        lineTemp = line3;
        lineLengTemp = line3Leng;
        setLineTemp = setLine3;
        setLineLengTemp = setLine3Leng;
        break;
      case 4:
        LINE = LINE_4;
        lineTemp = line4;
        lineLengTemp = line4Leng;
        setLineTemp = setLine4;
        setLineLengTemp = setLine4Leng;
        break;
      case 5:
        LINE = LINE_5;
        lineTemp = line5;
        lineLengTemp = line5Leng;
        setLineTemp = setLine5;
        setLineLengTemp = setLine5Leng;
        break;
      case 6:
        LINE = LINE_6;
        lineTemp = line6;
        lineLengTemp = line6Leng;
        setLineTemp = setLine6;
        setLineLengTemp = setLine6Leng;
        break;

      default:
        LINE = LINE_1;
        lineTemp = line1;
        lineLengTemp = line1Leng;
        setLineTemp = setLine1;
        setLineLengTemp = setLine1Leng;
        break;
    }

    if (LINE === null) return;

    setLineTemp(lineTemp + LINE.substring(lineLengTemp, lineLengTemp + 1));
    setLineLengTemp(lineLengTemp + 1);

    if (LINE.length === lineLengTemp) {
      if (line < 6) setLine(line + 1);
    }
  };

  const checkAnimationShell = () => {
    if (LINE_SHELL_1.length > lineShell1Leng) {
      setLineShell1(
        lineShell1 + LINE_SHELL_1.substring(lineShell1Leng, lineShell1Leng + 1)
      );
      setLineShell1Leng(lineShell1Leng + 1);
    } else {
      if (!lineShell2) setLineShell2(true);
      else {
        if (!lineShell3) setLineShell3(true);
        else {
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!isEnd()) {
        start && checkAnimationEditor();
      } else {
        checkAnimationShell();
      }
    }, 90);
    checkColor();
  });

  const shellRequest = (
    <span>
      <span className="color6">
        {new Date().getFullYear()}-
        {new Date().getMonth() + 1 < 10
          ? '0' + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1}
        -
        {new Date().getDay() < 10
          ? '0' + new Date().getDay()
          : new Date().getDay()}{' '}
        {new Date().getHours() < 10
          ? '0' + new Date().getHours()
          : new Date().getHours()}
        :
        {new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()}
        :
        {new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()}
      </span>{' '}
      [<span className="color2">200</span>]
    </span>
  );

  useEffect(() => {
    window.addEventListener('scroll', () =>
      setStart(isElementTotallyVisible(consoleRef))
    );

    return () => {
      window.removeEventListener('scroll', () =>
        setStart(isElementTotallyVisible(consoleRef))
      );
    };
  });

  return (
    <div
      className={`console ${customClasses}`}
      ref={consoleRef}
      id="console-content"
    >
      <div className="top">
        <div className="line">
          {[...Array(line)].map((n, i) => (
            <LineNumber key={i} number={i + 1}></LineNumber>
          ))}
        </div>
        <div className="code">
          <div className="line-content">
            <div
              className={`code__line ${line === 1 ? 'cursor' : ''}`}
              dangerouslySetInnerHTML={{ __html: line1 }}
            ></div>
          </div>

          <div className="line-content">
            <div
              className={`code__line ${line === 2 ? 'cursor' : ''}`}
              dangerouslySetInnerHTML={{ __html: line2 }}
            ></div>
          </div>

          <div className="line-content">
            <div
              className={`code__line ${line === 3 ? 'cursor' : ''}`}
              dangerouslySetInnerHTML={{ __html: line3 }}
            ></div>
          </div>

          <div className="line-content">
            <div
              className={`code__line tab-1 ${line === 4 ? 'cursor' : ''}`}
              dangerouslySetInnerHTML={{ __html: line4 }}
            ></div>
          </div>

          <div className="line-content">
            <div
              className={`code__line tab-1 ${line === 5 ? 'cursor' : ''}`}
              dangerouslySetInnerHTML={{ __html: line5 }}
            ></div>
          </div>

          <div className="line-content">
            <div
              className={`code__line ${
                line === 6 && !isEnd() ? 'cursor' : ''
              } ${isEnd() ? 'cursor-end' : ''}`}
              dangerouslySetInnerHTML={{ __html: line6 }}
            ></div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className={`bar ${!isEnd() ? 'active' : ''}`}>
          <div className="left">
            <div className={`status`}>
              {!isEnd() ? <span>INSERT</span> : <span>NORMAL</span>}
            </div>
            <div className="archive">server.js</div>
          </div>
          <div className="right">
            100% ⌯ {line}/{line} ln : {getLeng() + 1}
          </div>
        </div>
        <div className="shell">
          <div className="line-shell-content">
            <div
              className={`shell__line ${
                LINE_SHELL_1.length > lineShell1Leng && isEnd() ? 'cursor' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: `$ ${lineShell1}` }}
            ></div>
          </div>

          {lineShell2 && (
            <div className="line-shell-content">
              <div className="shell__line">
                {'>'} Ready! Waiting for requests...
              </div>
            </div>
          )}

          {lineShell3 && (
            <div className="line-shell-content">
              <div className="shell__line">
                {shellRequest} payment_intent.created
              </div>
            </div>
          )}

          {lineShell3 && (
            <div className="line-shell-content">
              <div className="shell__line">{shellRequest} charge.succeeded</div>
            </div>
          )}
          {lineShell3 && (
            <div className="line-shell-content">
              <div className="shell__line">
                {shellRequest} payment_intent.created
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
