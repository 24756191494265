import { React } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const PeopleOfficePlaceRoot = styled.section``;

const PeopleOfficePlaceRootHeader = styled.div`
  @media (max-width: 992px) {
    margin-bottom: 63px;
  }
  display: var(--etpay-display-grid);
  grid-template-columns: var(--etpay-grid-template-columns);
  column-gap: var(--etpay-grid-gap);
  margin-bottom: 100px;
`;

const PeopleOfficePlaceRootHeaderLeft = styled.div`
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  grid-column: span 5;
`;
const PeopleOfficePlaceRootHeaderLeftTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-15);
  line-height: 32px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-canes);
  margin-bottom: 34px;
`;

const PeopleOfficePlaceRootHeaderLeftSubtitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-26);
  line-height: 114%;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);
`;

const PeopleOfficePlaceRootHeaderRight = styled.div`
  @media (max-width: 992px) {
    grid-column: 1 / 7;
    margin-top: 21px;
  }
  grid-column: 6 / 9;
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-15);
  line-height: 32px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);

  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  align-items: center;
  justify-content: center;
`;

const PeopleOfficePlaceRootContent = styled.div`
  display: var(--etpay-display-grid);
  grid-template-columns: var(--etpay-grid-template-columns);
  column-gap: var(--etpay-grid-gap);

  @media (max-width: 992px) {
    row-gap: var(--etpay-grid-gap);
  }
`;

const PeopleOfficePlaceCard = styled.div`
  grid-column: span 4;
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  padding: 24px;
  background: var(--etpay-color-pure);
  border: 1px solid var(--etpay-color-ivory);
  border-radius: 36px;

  @media (max-width: 992px) {
    grid-column: span 6;
  }
`;

const PeopleOfficePlaceCardImage = styled.img`
  width: auto;
  height: 209px;
  border-radius: 24px;
  margin-bottom: 17px;
`;

const PeopleOfficePlaceCardTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: var(--etpay-font-size-15);
  line-height: 32px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);
  margin-bottom: 10px;
`;

const PeopleOfficePlaceCardContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: var(--etpay-font-size-10);
  line-height: 24px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);
`;

export const PeopleOfficePlace = () => {
  const intl = useIntl();
  return (
    <PeopleOfficePlaceRoot>
      <PeopleOfficePlaceRootHeader>
        <PeopleOfficePlaceRootHeaderLeft>
          <PeopleOfficePlaceRootHeaderLeftTitle>
            {intl.formatMessage({ id: "peopleOfficePlace.text1" })}
          </PeopleOfficePlaceRootHeaderLeftTitle>
          <PeopleOfficePlaceRootHeaderLeftSubtitle>
            {intl.formatMessage({ id: "peopleOfficePlace.text2" })}
          </PeopleOfficePlaceRootHeaderLeftSubtitle>
        </PeopleOfficePlaceRootHeaderLeft>
        <PeopleOfficePlaceRootHeaderRight>
          {intl.formatMessage({ id: "peopleOfficePlace.text3" })}
        </PeopleOfficePlaceRootHeaderRight>
      </PeopleOfficePlaceRootHeader>
      <PeopleOfficePlaceRootContent>
        <PeopleOfficePlaceCard>
          <PeopleOfficePlaceCardImage src={require('../../assets/images/people/img_003.webp')} />
          <PeopleOfficePlaceCardTitle>
            {intl.formatMessage({ id: "peopleOfficePlace.santiago" })}
          </PeopleOfficePlaceCardTitle>
          <PeopleOfficePlaceCardContent>
            {intl.formatMessage({ id: "peopleOfficePlace.santiagoContent" })}
            <p>Correo: <a href="mailto:ventas.chile@etpay.com">ventas.chile@etpay.com</a></p>
            <p>Correo: <a href="mailto:servicesdesk@etpay.com">servicesdesk@etpay.com</a></p>
            <p>Tel: <a href="tel:+562 3210 1585">+562 3210 1585</a></p>
          </PeopleOfficePlaceCardContent>
        </PeopleOfficePlaceCard>
        <PeopleOfficePlaceCard>
          <PeopleOfficePlaceCardImage src={require('../../assets/images/people/img_004.webp')} />
          <PeopleOfficePlaceCardTitle>
            {intl.formatMessage({ id: "peopleOfficePlace.mexico" })}
          </PeopleOfficePlaceCardTitle>
          <PeopleOfficePlaceCardContent>
            {intl.formatMessage({ id: "peopleOfficePlace.mexicoContent" })}
            <p>Correo: <a href="mailto:ventas.mexico@etpay.com">ventas.mexico@etpay.com</a></p>
            <p>Correo: <a href="mailto:servicesdesk@etpay.com">servicesdesk@etpay.com</a></p>
            <p>Tel: <a href="tel:+52 55 4169 7254">+52 55 4169 7254</a></p>
          </PeopleOfficePlaceCardContent>
        </PeopleOfficePlaceCard>
      </PeopleOfficePlaceRootContent>
    </PeopleOfficePlaceRoot>
  );
};
