import { useEffect, useState, createRef } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

import {
  Banner,
  Button,
  Console,
  Content,
  EasyIntegration,
  Icon,
  Layout,
  ReadyToStart,
  SafeMethod,
  Slider,
  SliderItem,
  SpecialSection,
  Tips,
  BankInformation,
  BankTypes,
  BrandList,
  Tabs,
} from "../../components";
import "../../components/Banner/Banner.scss";
import { SafePayment } from "../../components/SafePayment/SafePayment";
import { WhyETPay } from "../../features/WhyETPay/WhyETPay";
import "./Home.scss";
import useAppContext from "../../context/Context";
import isElementTotallyVisible from "../../services/detectElement";
import stars from "../../assets/stars.svg";

export const Home = () => {
  // const video = document.getElementsByTagName('video')

  // console.log(video)

  ReactGA.send({ hitType: "pageview", page: "/" });
  const { activeCountry } = useAppContext()

  const intl = useIntl();
  const { getImages } = useAppContext();

  let [cardlessShow, setCardlessShow] = useState(false);
  const cardlessRef = createRef(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (cardlessRef.current) {
        if (
          window.innerHeight > cardlessRef.current.getBoundingClientRect().top
        ) {
          setCardlessShow(
            window.innerHeight - cardlessRef.current.getBoundingClientRect().top
          );
        }
      }
    });
  });
  return (
    <Layout className="home">
      <Banner>
        <Content
          title={intl.formatMessage({ id: "banner.home.title" })}
          subtitle={intl.formatMessage({ id: "banner.home.subtitle" })}
          footer={
            <>
              <a
                href={intl.formatMessage({
                  id: "button.testFree.url",
                })}
              >
                <Button bgColor={"#72DBAA"} bgHoverColor="#140F33">
                  <Icon name={"Icon_Pre"} className="fillable" />
                  <span>
                    {intl.formatMessage({ id: "button.testFree.text" })}
                  </span>
                </Button>
              </a>
              <a href={
                'tel:'+intl.formatMessage({ id: "banner.home.button2.url" })
              }>
                <Button bgColor={"#F2F2F2"} bgHoverColor="#140F33">
                  <span>
                    {intl.formatMessage({ id: "banner.home.button2.text" })+intl.formatMessage({ id: "banner.home.button2.url" })}
                  </span>
                  <Icon name="icon-phone"></Icon>
                </Button>
              </a>



              <div className="promotion">
                <img src={stars} alt="Multistars" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "banner.promo" }),
                  }}
                />
              </div>
            </>
          }
        />
        <img
          className="back-icon"
          src={getImages["banner_background_home"]}
          alt=""
        />
        <div className="image-banner hide-xs">
          {/* <img src={getImages["banner_image_home"]} /> */}
          {activeCountry === 'cl' ? <iframe src='https://my.spline.design/devicemodelcopy-b36170ba32adfd8f58853751ba795bac/' frameborder='0' width='100%' height='100%'></iframe> : <iframe src='https://my.spline.design/etpayheromex-3e39937085fe0a42a29b3287b34db0b4/' frameborder='0' width='100%' height='100%'></iframe>}
        </div>
      </Banner>

      <div className="layout-container section-2">
        <div className="flex-grid">
          <div className="flex-grid__cols-12  flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none">
            <BrandList
              brandsGroup={{
                cl: [
                  getImages["brand_001"],
                  getImages["brand_002"],
                  getImages["brand_003"],
                  getImages["brand_004"],
                  getImages["brand_005"],
                ],
                es: [
                  getImages["brand_001"],
                  getImages["brand_003"],
                  getImages["brand_004"],
                ],
                mx: [
                  getImages["brand_002"],
                  getImages["brand_004"],
                  getImages["brand_005"],
                ],
              }}
            />
          </div>
        </div>
      </div>
      <Tips
        tip1={useIntl().formatMessage({ id: "home.tip1" })}
        tip2={useIntl().formatMessage({ id: "home.tip2" })}
        tip3={useIntl().formatMessage({ id: "home.tip3" })}
      ></Tips>
      <div className="layout-container section_3">
        {/* <DevLayout /> */}
        <div className="flex-grid">
          <div
            style={{ zIndex: 1 }}
            className="flex-grid__cols-8 flex-grid__cols__offset-2  flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none"
          >
            <div className="section_3__subsection_2">
              <div
                ref={cardlessRef}
                className="cardless"
                style={{ transform: `translateY(${cardlessShow * 0.35}px)` }}
              >
                Cardless
              </div>
            </div>
          </div>

          <div
            style={{ zIndex: 10 }}
            className="flex-grid__cols-8 flex-grid__cols__offset-2  flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none"
          >
            <div className="section_3__subsection_3">
              <BankTypes
                className="bank-types"
                banksGroup={{
                  chile: [
                    getImages["bank_1"],
                    getImages["bank_2"],
                    getImages["bank_3"],
                    getImages["bank_4"],
                    getImages["bank_5"],
                    getImages["bank_6"],
                    getImages["bank_7"],
                    getImages["bank_8"],
                    getImages["bank_9"],
                    getImages["bank_10"],
                    getImages["bank_11"],
                    getImages["bank_12"],
                    getImages["bank_13"],
                    getImages["bank_14"],
                  ],
                }}
              />
            </div>
          </div>

          <div className="flex-grid__cols-4 flex-grid__cols__offset-2 static-form  flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none">
            <div className=" section_3__subsection_4" style={{ overflow: "hidden", height: '586px', borderRadius: '34px', background: '#f9f9f9', boxShadow: '2px 0px 34px rgb(88 87 87 / 14%)' }}>
              <video src={getImages["module_form"]} className="inline-form" style={{ width: '100%', objectFit: 'contain', borderRadius: '34px', height: '100%' }} autoPlay={true} muted={true} playsInline>
              </video>
            </div>
          </div>

          <div className="flex-grid__cols-4  flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none">
            <div className="section_3__subsection_5">
              <BankInformation className="bank-information"></BankInformation>
            </div>
          </div>

          <div className="flex-grid__cols-8 flex-grid__cols__offset-2 flex-grid__mobile__cols-6 flex-grid__mobile__cols__offset-none">
            <div className="section_3__subsection_6">
              <SafePayment></SafePayment>
            </div>
          </div>
        </div>
      </div>

      <div>
        <SafeMethod></SafeMethod>
        <Slider
          title={intl.formatMessage({ id: "home.slider.title" })}
          description={intl.formatMessage({
            id: "home.slider.description",
          })}
        >
          {activeCountry === 'mx' ? <SliderItem
            picture={getImages["slider_4"]}
            title={intl.formatMessage({
              id: "useCases.slider2.item1.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider2.item1.description",
            })}
            service="quicktransfer"
          ></SliderItem> :
            <SliderItem
              picture={getImages["slider_1"]}
              title={intl.formatMessage({
                id: "useCases.slider1.item1.title",
              })}
              description={intl.formatMessage({
                id: "useCases.slider1.item1.description",
              })}
              service="paymentlink"
            ></SliderItem>
          }
          <SliderItem
            picture={getImages["slider_5"]}
            title={intl.formatMessage({
              id: "useCases.slider2.item2.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider2.item2.description",
            })}
            service="quicktransfer"
          ></SliderItem>
          {activeCountry === 'mx' ? <SliderItem
            picture={getImages["slider_6"]}
            title={intl.formatMessage({
              id: "useCases.slider2.item3.title",
            })}
            description={intl.formatMessage({
              id: "useCases.slider2.item3.description",
            })}
            service="quicktransfer"
          ></SliderItem> :
            <SliderItem
              picture={getImages["slider_3"]}
              title={intl.formatMessage({
                id: "useCases.slider1.item3.title",
              })}
              description={intl.formatMessage({
                id: "useCases.slider1.item3.description",
              })}
              service="paymentlink"
            ></SliderItem>}
        </Slider>
        <WhyETPay />
        <SpecialSection>
          <EasyIntegration customClasses="home__easy-integration">

            <Link to={intl.formatMessage({ id: "easy_integration.home.button.url" })}><Button bgColor={"#857BF9"} color="#fff" bgHoverColor="#140F33">
              <span>
                {intl.formatMessage({ id: "easy_integration.home.button" })}
              </span>
              <Icon name={"Icon_After"} color="#fff" />
            </Button></Link>
          </EasyIntegration>
          <Console customClasses="home__console"></Console>
          <div className="tabs-items">
            {activeCountry === 'mx' ?
              <Tabs
                list={[
                  {
                    header: intl.formatMessage({
                      id: "integration.tab.api.title",
                    }),
                    body: intl.formatMessage({
                      id: "integration.tab.api.description",
                    }),
                  },
                ]}
                page="home"
              /> :
              <Tabs
                list={[
                  {
                    header: intl.formatMessage({
                      id: "integration.tab.api.title",
                    }),
                    body: intl.formatMessage({
                      id: "integration.tab.api.description",
                    }),
                  },
                  {
                    header: intl.formatMessage({
                      id: "integration.tab.integration.title",
                    }),
                    body: intl.formatMessage({
                      id: "integration.tab.integration.description",
                    }),
                  },
                ]}
                page="home"
              />
            }
          </div>
        </SpecialSection>
        <ReadyToStart></ReadyToStart>
      </div>
    </Layout>
  );
};
